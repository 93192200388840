/* used to fine-tune where the images are framed so that no-one's face is cut off.*/

#BenEng {
    object-position: center;
}

#JohDee {
    object-position: center;
}

#EriGon {
    object-position: left;
}

#JerTif {
    object-position: center
}

#KriHod {
    object-position: top;
}

#ScoDei {
    object-position: top 25% left 0;
}

#BilMar {
    object-position: top center;
}

#CurEle {
    object-position: top;
}

#CodSha {
    object-position: top;
}

#EriSch {
    object-position: center;
}

#CodRuh {
    object-position: center;
}

#BreWri {
    object-position: center;
}

#DonLuc {
    object-position: center;
}

#CamBis {
    object-position: right;
}

#LiaZhe {
    object-position: center;
}

#TriTif {
    object-position: center;
}

#AleCou {
    object-position: top;
}

#RobMur {
    object-position: left;
}

#TimKea {
    object-position: center;
}

#RicRob {
    object-position: top 40% left 0;
}

/*  this is the actual styling for the page: */

.TeamList {
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    background: -moz-linear-gradient(45deg, rgba(238,248,248,0.32394964821866246) 0%, rgba(29,97,135,0.8029412448573179) 64%);
    background: -webkit-linear-gradient(45deg, rgba(238,248,248,0.32394964821866246) 0%, rgba(29,97,135,0.8029412448573179) 64%);
    background: linear-gradient(45deg, rgba(238,248,248,0.32394964821866246) 0%, rgba(29,97,135,0.8029412448573179) 64%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eef8f8",endColorstr="#1d6187",GradientType=1);
    background-color: #EEAECA;
}

#endList {
    width: 100%;
}

.TeamList__employeeContainer {
    height: 100%;
    width: 98%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 2%;
}

.employeeContainer__employee {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #FFFFFF;
    margin: 1% 0% 1% 0%;
    border: 15px ridge #FFFF87;
    border-radius: 25px;

}

.employee__img {
    height: 450px;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
}


.employee__copyContainer {
    /* border: 1px solid purple; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin: 5% 0 5% 0;
}


.copyContainer__name {
    font-family: 'Courier New', Courier, monospace;
    font-size: 3rem;
    width: 100%;
    text-align: center;
}

.firstName {
    color: #0B72B6;
    letter-spacing: 2px;
    font-weight: 1000;
    width: 100%;
}

.lastName {
    color: #000000;
    font-style: italic;
    opacity: 40%;
    font-weight: 1000;
    margin-left: 5%;
    width: 100%;

}

.copyContainer__rule {
    width: 60%;
    margin-top: 2%;
    padding-top: .5%;
    background-color: #000000;
    border-radius: 25px;
    opacity: 10%;
    border-radius: 15px;
}

.copyContainer__info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 2% 0% 2% 0%;
}

.info__position {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.75rem;
    font-weight: 600;
    color: #000000;
    opacity: 70%;
    padding-bottom: 1.2%;
}

.info__years {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.25rem;
    color: #000000;
    font-style: italic;
    letter-spacing: .75px;
    opacity: 60%;
    padding-top: 2%;
}


@media only screen and (max-width: 1024px) {
    .employeeContainer__employee {
        width: 45%;
    }
    .employee__img {
        height: 250px;
    }
    .copyContainer__name {
        font-size: 2.2rem;
        margin-top: 5%;
        margin-bottom: 3%;
    }
    .info__position {
        font-size: 1.4rem;
    }
    .info__years {
        font-size: 1.4rem;
    }
}


@media only screen and (max-width: 600px) {
    .employeeContainer__employee {
        margin-top: 5%;
        width: 100%;
    }
    .TeamList__employeeContainer > :last-child {
        margin-bottom: 9%;
    }
    .employee__img {
        width: 100%;
        height: 350px;
    }
    .copyContainer__info {
        margin-bottom: 7%;
    }
    .copyContainer__name {
        font-size: 2.2rem;
        margin: 9% 0 3% 0;
        padding: 0 0 0 0;
    }
    .copyContainer__rule {
        display: none;
    }
    .info__position {
        width: 100%;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 400;
        margin-bottom: 3%;
    }
    .info__years {
        font-size: 1.4rem;
        margin: 2% 0 2% 0;
    }


}
