.SalesApplication {
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
}

#form_salesapplication {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}


.salesapplication__subheaderContainer {
    /* border: 2px solid green; */
    height: 100%;
    width: 30%;
    margin: 1% 0 1% 3%;
}

hr {
    color: #1E132A;
    height: 5px;
    background-color: #1E132A;
    opacity: 50%;
}

.salesapplication__subheaderContainer > h1 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    font-weight: 1000;
    letter-spacing: .5px;
    color: #1E132A;
}

.salesapplication__piiContainer {
    /* border: 2px solid green; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin: 1% 0 1% 0;
}

.piiContainer__inputContainer {
    /* border: 1px solid orange; */
    height: 100%;
    width: 30%;
    margin: 1% 0 1% 3%;
    /* type styling */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 600;
    color: #1E132A;
    /* end type styling */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.piiContainer__inputContainer > input {
    height: 30px;
    width: 250px;
    border: 1.5px solid #1E132A;
    border-radius: 5px;
    transition: .2s ease-in-out;
}

.piiContainer__inputContainer > input:focus {
    box-shadow: 5px 4px #008000;
}

#container_authheader {
    margin-top: 3%;
    margin-bottom: -.5%;
}

.salesapplication__authContainer {
    /* border: 2px solid maroon; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin: 1% 0 2% 0;
}

.authContainer__inputContainer {
    /* border: 2px solid goldenrod; */
    height: 100%;
    width: 24.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1% 0 1% 3%;
    /* font styling  */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.75rem;
    font-weight: 500;
    color: #1E132A;
}

.authContainer__inputContainer > input {
    height: 15px;
    width: 15px;
    transition: .2s ease-in-out;
}

.authContainer__inputContainer > input:focus {
    height: 25px;
    width: 25px;
    margin-right: 2%;
}

#container_certheader {
    margin-bottom: -.5%;
    margin-top: 3%;
}

.salesapplication__certContainer {
    /* border: 2px solid deepskyblue; */
    height: 100%;
    width: 90%;
    margin: 2% 0 2% 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    /* font styling */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 600;
    color: #1E132A;
    /* end font stlyes */
    transition: .5s ease-in-out;
}


.salesapplication__listContainer {
    /* border: 2px solid grey; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* font styling */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    color: #1E132A;
    /* end font styles */
    margin: 2.5% 0 1% 0;
    transition: .5s ease-in-out;
}

.certContainer__inputContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 40%;
}

.certContainer__inputContainer > input {
    height: 15px;
    width: 15px;
    transition: .2s ease-in-out;
}

.certContainer__inputContainer > input:focus {
    height: 25px;
    width: 25px;
    margin-right: 2%;
}

.listContainer__inputContainer > input {
    height: 30px;
    width: 250px;
    border: 1.5px solid #1E132A;
    border-radius: 5px;
    transition: .2s ease-in-out;
    margin: 5% 0 5% 0;
}

.listContainer__inputContainer > input:focus {
    box-shadow: 5px 4px #008000;
}

.salesapplication__brandsContainer {
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    margin: 1% 0 1% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.salesapplication__brandsContainer > p {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 600;
    margin: 1% 0 1% 3%;
}

.salesapplication__selectContainer {
    /* border: 2px solid greenyellow; */
    height: 100%;
    width: 80%;
    margin: 1% 0 1.5% 8%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.salesapplication__select {
    height: 30px;
    width: 250px;
    border: 1.5px solid #1E132A;
    border-radius: 5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    color: #FFFFFF;
    background-color: #1E132A;
    transition: .2s ease-in-out;
}

.salesapplication__select:focus {
    box-shadow: 5px 4px #008000;        
}

#container_JHheader {
    margin: 2% 0 -1% 3%;
}

.salesapplication__jobhistoryContainer {
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.jobhistoryContainer__job {
    /* border: 2px solid grey; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    /* font styles */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.75rem;
    font-weight: 500;
    color: #1E132A;
    /* end font styles */
    margin: 2% 0 2% 0;
}

.jobhistoryContainer__subheaderContainer {
    font-size: 1.75rem;
    font-weight: 600;
    margin: 1% 0 .1% 3%;
    width: 10%;
}

.jobhistoryContainer__subheaderContainer > hr {
    height: 3px;
}

.job__inputContainer {
    /* border: 1px solid orange; */
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1% 0 1% 3%;
}

/* doing specific fixes here: */


.jobhistoryContainer__job > div > input[type="text"] {
    height: 30px;
    width: 250px;
    border: 1.5px solid #1E132A;
    border-radius: 5px;
    transition: .2s ease-in-out;
}

.jobhistoryContainer__job > div >input[type="date"] {
    height: 30px;
    width: 250px;
    border: 1.5px solid #1E132A;
    border-radius: 5px;
    transition: .2s ease-in-out;
    padding-left: 5%;
}

.jobhistoryContainer__job > div > input[type="checkbox"] {
    transition: .2s ease-in-out;
}

.jobhistoryContainer__job > div > input[type="text"]:focus {
    box-shadow: 5px 4px #008000;        
}

.jobhistoryContainer__job > div > input[type="date"]:focus {
    box-shadow: 5px 4px #008000;        
}

.jobhistoryContainer__job > div > input[type="checkbox"]:focus {
    height: 25px;
    width: 25px;
    margin-right: 2%;
}

.jobhistoryContainer__job > div > textarea {
    height: 100px;
    width: 700px;
    border: 1.5px solid #1E132A;
    border-radius: 5px;
    transition: .2s ease-in-out;
    resize: none;
}

.jobhistoryContainer__job > div > textarea:focus {
    box-shadow: 5px 4px #008000;        

}

#job__textareaContainer {
    width: 70%;
}

.salesapplication__coverletterContainer {
    /* border: 2px solid green; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    /* font stlyes */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 600;
    /* end font styles */
}

.salesapplication__coverletterContainer > lable {
    margin: 1% 0 .01% 3%;
}

.salesapplication__coverletterContainer > textarea {
    height: 200px;
    width: 90%;
    margin: 1% 0 1% 3%;
    border: 1.5px solid #1E132A;
    border-radius: 5px;
    transition: .2s ease-in-out;
    resize: none;
}

.salesapplication__coverletterContainer > textarea:focus {
    box-shadow: 5px 4px #008000;        
}

.salesapplication__errors {
    /* border: 1px solid blue; */
    height: 100%;
    width: 60%;
    margin: 1% 0 1% 3%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.salesapplication__errors > p {
    font-size: 2rem;
    font-weight: 1000;
    color: #F20000;
    margin-bottom: .5%;
    letter-spacing: 1.5px;
}

.salesapplication__errors > ul > li {
    font-size: 1.5rem;
    padding: 0 0 .75% 3%;
    color: #F20000;
}

input[type="submit"] {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: #1E132A;
    color: #FFFFFF;
    border-radius: 5px;
    height: 75px;
    width: 300px;
    transition: .2s ease-in-out;
    margin: 1% 0 2% 77.25%
    
}

input[type="submit"]:hover {
    background-color: #008000;
}





@media only screen and (max-width: 1024px) {
    .salesapplication__subheaderContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 5% 0 1.5% 0;
        width: 100%;

    }
    .piiContainer__inputContainer {
        width: 90%;
    }
    .piiContainer__inputContainer > input {
        width: 350px;
    }
    .authContainer__inputContainer {
        width: 90%;
        margin: 2% 0 2% 4%;
    }
    .salesapplication__subheaderContainer > h1 {
      width: 90%;  
      margin-left: 3%;
    } 
    #container_authheader {
        margin: 7% 0 1.5% 0;

    }
    #container_certheader > :first-child {
        font-size: 2.5rem;
        margin-top: 5%;
    }
    .application_HR {
        width: 96%;
        
    }
    .salesapplication__lable {
        font-size: 1.6rem;
        line-height: 2.2rem;
        font-weight: 400;
    }
    #lable_workCheck {
        width: 90%;
    }
    #container_certheader {
        margin-bottom: 2%;
    }
    #container_certheader > :first-child {
        font-size: 2rem;
    }
    .certContainer__inputContainer {
        width: 100%;
    }
    #certCheck {
        height: 25px;
        width: 25px;
    }
    .salesapplication__listContainer {
        flex-direction: column;
        margin-top: 7%;
    }
    .listContainer__instructions {
        font-size: 1.5rem;
        font-family: 'Courier New', Courier, monospace;
        line-height: 1.9rem;
        text-align: center;
        font-weight: 600;
    }
    .salesapplication__brandsContainer > p {
        font-size: 1.7rem;
        line-height: 2.1rem;
        font-weight: 400;
    }
    .salesapplication__selectContainer {
        flex-direction: column;
    }
    .salesapplication__selectContainer > select {
        margin: 3% 0 3% 0;
    }
    #container_JHheader {
        margin: 3% 0 2% 0;
    }
    .jobhistoryContainer__subheaderContainer {
        width: 50%;
    }
    .job__inputContainer {
        width: 90%;
        justify-content: space-between;
    }
    .jobhistoryContainer__job > div > input[type="date"] {
        width: 50%;
    }
    #lable_jobhistory_curemployment_1 {
        padding: 5% 0 5% 0;
    }
    #lable_jobhistory_curemployment_2 {
        padding: 5% 0 5% 0;
    }
    #lable_jobhistory_curemployment_3 {
        padding: 5% 0 5% 0;
    }
    #job__textareaContainer {
        width: 90%;
        flex-direction: column;
    }
    .jobhistoryContainer__job > div > textarea {
        width: 100%;
        height: 200px;
        margin-top: 3%;
    }
    #lable_jobhistory_desc_1 {
        font-family: 'Courier New', Courier, monospace;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 600;
    }
    .salesapplication__coverletterContainer > lable {
        font-weight: 600;
        width: 90%;
    }
    input[type="submit"] {
        margin-left: 7.7%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
    .salesapplication__errors {
        width: 90%;
        margin-top: 5%;
    }
    .salesapplication__errors > p {
        font-size: 1.6rem;
        margin-bottom: 3%;
    }
    .salesapplication__error {
        margin-top: 1.5%;
    } 
}

@media only screen and (max-width: 600px) {
    input[type="submit"] {
        margin-left: 9.5%;
    }
    .piiContainer__inputContainer > input {
        width: 150px;
    }
}