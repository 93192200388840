.Apply {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-image: url("../../assets/textures/texture3_black&purp.svg");
}

.Apply__link {
    border: 1px solid #FFFFFF;
    height: 100%;
    width: 80%;
    text-decoration: none;
    margin: 1.5% 0 2% 0;
    border-radius: 12px;
    transition: .7s ease-in-out;
    background: rgb(238,248,248);
    background: -moz-linear-gradient(270deg, rgba(238,248,248,0.2847339619441527) 0%, rgba(0,0,0,1) 80%);
    background: -webkit-linear-gradient(270deg, rgba(238,248,248,0.2847339619441527) 0%, rgba(0,0,0,1) 80%);
    background: linear-gradient(270deg, rgba(238,248,248,0.2847339619441527) 0%, rgba(0,0,0,1) 80%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eef8f8",endColorstr="#000000",GradientType=1); 
}

.Apply__link:hover {
    background-color: #3C3C60;
    border-color: #3C3C60;
}

.Apply__link:focus {
    background-color: #3C3C60;
    border-color: #3C3C60;
}

.Apply__link:hover .button__icon {
    height: 100px;
    width: 100px;
}

.Apply__link:focus .button__icon {
    height: 100px;
    width: 100px;
}

.Apply__link:hover #linkedin {
    border: 2.5px solid #FFFFFF;
}

.Apply__link:focus #linkedin {
    border: 2.5px solid #FFFFFF;
}


.button__lable {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 5rem;
    color: #FFFFFF;
    margin: 4% 0 4% 7%;
    font-weight: 400;
}

.link__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button__icon {
    height: 0px;
    width: 100px;
    transition: .5s ease-in-out;
    margin: 0 10.5% 0 0;
}

#linkedin {
    background-image: url("../../assets/icons/linkedin.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 5px;
    transition: .375s ease-in-out;
}

#facebook {
    background-image: url("../../assets/icons/facebook_black.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

#website {
    background-image: url("../../assets/icons/ourwebsite.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; 
}


@media only screen and (max-width:1024px) {
    .Apply > :nth-child(2) {
        margin-top: 5%;
    }
    .Apply > :last-child {
        margin-bottom: 5%;
    }
    .button__lable {
        font-size: 3rem;
        padding: 7% 0 7% 0;
    }
    .Apply__link {
        width: 90%;
    }
    .Apply__link:hover .button__icon {
        height: 75px;
        width: 75px;
    }
}

@media only screen and (max-width: 600px) {
    .Apply {
        flex-direction: column;
    }

    .Apply__link {
        width: 92%;
        margin: 0% 0 0 0;
    }
    .button__lable {
        font-size: 3rem;
        line-height: 4.2rem;
        font-weight: 400;
    }
    .Apply__link:hover .button__icon {
        height: 50px;
        width: 50px;
        padding: 7% 7% 7% 7%;
        margin: 3% 9% 5% 0;
    }
    .Apply > :nth-child(2) {
        margin-bottom: 7%;
    }

    .Apply > :nth-child(4) {
        margin-top: 5%;
    }
}