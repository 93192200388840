.BBSForm {
    height: 100%;
    width: 100%;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(350deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.bbsform__form {
    padding: 1% 0 3% 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.bbsform__label {
    color: #FFFFFF;
    font-size: 2.4rem;
    margin: 3% 0 .75% 0;
}

.bbsform__input {
    height: 40px;
    width: 30%;
    margin-bottom: 2%;
}

.bbsform__select {
    height: 40px;
    width: 30%;
}

.bbsform__textarea {
    padding: 1% 1% 0 1%;
}

#bbsform_comment {
    height: 350px; 
    max-height: 450px; 
    min-height: 100px; 
    width: 70%; 
    max-width: 1500px; 
    min-width: 250px;
}

.BBSForm__loadingContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to{
        transform: rotate(359deg);
    }
}

.BBSForm__loadingContainer {
    height: 100%;
    width: 100%;
    padding: 10% 0 10% 0;
}

.three-quarter-spinner {
    width: 250px;
    height: 250px;
    border: 3px solid transparent;
    box-shadow: 4px 2px 15px 7px #ffff78;
    border-radius: 50%;
    animation: spin .5s linear 0s infinite;
    margin: 7% 0 7% 0;
}

.bbsform__errors {
    height: 100%;
    width: 50%;
    background-color: #FFFFFF;
    border: 2px solid #F20000;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    color: #F20000;
    padding: 3% 0 3% 3%;
    font-size: 2rem;
}

.errors__dialog {
    margin: 0 0 1% 0;
}

.bbsform__error {
    margin-left: 3%;
}


@media only screen and (max-height: 800px) {
    .bbsform__label {
        font-size: 1.8rem;
        margin-bottom: 1%;
    }

    .bbsform__input {
        width: 95%;
        margin: 2% 0 3% 0;
    }
    
    .bbsform__select {
        width: 95%;
        margin: 2% 0 3% 0;

    }
    
    .bbsform__textarea {
        width: 95%;
        margin: 2% 0 3% 0;

    }
    
    #bbsform_comment {
        width: 95%;
    }

}