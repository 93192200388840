.NewTicket {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(350deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff;
    font-weight: 800;
    font-size: 1.6rem;
}

.newTicket__form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 1.5% 0 0 2%;
}

.form__sectionTitle {
    font-size: 3rem;
    margin: 2% 0 0 0;
    text-decoration: underline;
}

.form__fieldCont {
    height: 100%;
    width: 100%;
    /* border: 2px solid red; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.fieldCont__field {
    height: 100%;
    width: 33%;
    /* border: 2px solid yellow; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1% 0 1% 1%;
}

.newTicket__label {
    margin: 0 0 1% 0;
}

.newTicket__input {
    height: 40px;
    width: 90%;
    padding: 1% 1% 1% 1%;
    font-size: 1.4rem;
}

.newTicket__select {
    height: 40px;
    width: 90%;   
    padding: 1% 1% 1% 1%;
    font-size: 1.4rem;
}

.newTicket__textarea {
    height: 200px;
    width: 96%;
    margin: 1.5% 0 0 0;
    padding: 1% 1% 1% 1%;
    font-size: 1.4rem;
}

#description {
    width: 90%;
}

.newTicket__errors {
    background-color: #FF0000;
    height: 100%;
    width: 50%;
    padding: 1.25% 1% 1% 1%;
    margin-left: 1%;
}

.errors__dialog {
    font-size: 2rem;
    text-decoration: underline;
    line-height: 120%;
}

.newTicket__error {
    color: #ffffff;
    margin: 1% 0 1% 0;
}

.newTicket__loadingContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to{
        transform: rotate(359deg);
    }
}

.newTicket__loadingContainer {
    height: 100%;
    width: 100%;
    padding: 10% 0 10% 0;
}

.three-quarter-spinner {
    width: 250px;
    height: 250px;
    border: 3px solid transparent;
    box-shadow: 4px 2px 15px 7px #ffff78;
    border-radius: 50%;
    animation: spin .5s linear 0s infinite;
    margin: 7% 0 7% 0;
}

@media only screen and (max-width: 1200px) {
    .newTicket__errors {
        width: 85%;
        padding: 3% 3% 3% 3%;
    }

}

@media only screen and (max-width: 1024px) {     
    .fieldCont__field {
        width: 50%;
    }

    .newTicket__label {
        font-weight: 300;
        width: 80%;
        margin-bottom: 1.5%;
    }
}

@media only screen and (max-width: 800px) {
    .form__sectionTitle {
        text-decoration: none;
        margin: 4% 0 1% 0;
    }

    .form__fieldCont {
        margin: 3% 0 3% 0;
    }
    
    .fieldCont__field {
        width: 100%;
        margin: 2% 0 2% 0;
    }

    .newTicket__label {
        line-height: 120%;
    }

    #description {
        width: 100%;
    }

    .newTicket__errors {
        width: 94%;
        padding-top: 4%;
    }

    .errors__dialog {
        font-size: 1.6rem;
        margin-bottom: 3%;
    }

    .newTicket__error {
        font-size: 1.2rem;
        margin-bottom: 3%;
        line-height: 120%;
    }
}