.Confirmation {
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #000000;
}

.Confirmation__container_1 {
    /* border: 2px solid green; */
    height: 100%;
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    background-color: #000000;
}

.container_1__title {
    font-size: 6.5rem;
    font-weight: 1000;
    color: #FFFFFF;
    letter-spacing: 4px;
    margin: 18% 0 5% 5%;
    line-height: 7.25rem;
    height: 100%;
    width: 90%;
}

.container_1__subtitle {
    height: 100%;
    width: 85%;
    background-color: #FFFFFF;
    color: #000000;
    padding: 3% 1% 3% 3%;
    font-size: 2rem;
    font-style: italic;
    font-weight: 600;
    margin: 0% 0 19% 5%;
}

.Confirmation__container_2 {
    /* border: 2px solid red; */
    height: 100%;
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: rgb(29,97,135);
    background: -moz-linear-gradient(127deg, rgba(29,97,135,0.773546918767507) 17%, rgba(255,255,255,0.6054796918767507) 100%);
    background: -webkit-linear-gradient(127deg, rgba(29,97,135,0.773546918767507) 17%, rgba(255,255,255,0.6054796918767507) 100%);
    background: linear-gradient(127deg, rgba(29,97,135,0.773546918767507) 17%, rgba(255,255,255,0.6054796918767507) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1d6187",endColorstr="#ffffff",GradientType=1);
    background-color: #EEAECA;
}

.container_2__copy {
    font-size: 2.5rem;
    font-weight: 600;
    margin: 1% 0 1% 0;
}


.clouds {
    position:relative;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    transform: translateZ(0);
    padding: 9% 9% 9% 20%;
  }
  
  .cloud {
    position: absolute;
    top: 20%;
    width: 300px;
    right: 0;
    opacity: 1;
  }
  
  .cloud.front {
    z-index: 9;
  }
  
  .cloud.distant {
    z-index: 1;
  }
  
  .cloud.background {
    z-index: 1;
  }
  
  /*Cloud Sizing*/
  .cloud.smaller {
    margin-right: 400px;
    width: 100px;
    margin-top: 50px;
  }
  
  .cloud.small {
    margin-right: 200px;
    width: 150px;
  }

/*Cloud: Animation*/
.cloud {
    -webkit-animation-name: cloud-movement;
    -webkit-animation-timing-function: linear;
    -webkit-animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 8s;
  
    -moz-animation-name: cloud-movement;
    -moz-animation-timing-function: linear;
    -moz-animation-direction: forwards;
    -moz-animation-iteration-count: infinite;
    -moz-animation-duration: 8s;
  
    animation-name: cloud-movement;
    animation-timing-function: linear;
    animation-direction: forwards;
    animation-iteration-count: infinite;
    animation-duration: 8s;
  }
  
  .slow {
    -webkit-animation-duration: 9.2s;
    -moz-animation-duration: 9.2s;
    animation-duration: 9.2s;
  }
  
  .slower {
    -webkit-animation-duration: 11.2s;
    -moz-animation-duration: 11.2s;
    animation-duration: 11.2s;
  }
  
  .slowest {
    -webkit-animation-duration: 13.5s;
    -moz-animation-duration: 13.5s;
    animation-duration: 13.5s;
  }
  
  .super-slow {
    -webkit-animation-duration: 20.5s;
    -moz-animation-duration: 20.5s;
    animation-duration: 20.5s;
  }
  
  @keyframes cloud-movement {
    0% {
      opacity: 0.1;
      -webkit-transform: translateX(300px);
      -moz-transform: translateX(300px);
      transform: translateX(300px);
    }
    10% {
  
      opacity: 0.7;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 0;
      -webkit-transform: translateX(-1000px);
      -moz-transform: translateX(-1000px);
      transform: translateX(-1000px);
    }
  }



  @media only screen and (max-width: 1024px) {
    .Confirmation {
      flex-direction: column;
    }
    .Confirmation__container_1 {
      width: 100%;
    }
    .container_1__title {
      font-size: 5rem;
      line-height: 6rem;
    }
    .Confirmation__container_2 {
      width: 100%;
      height: 400px;
    }
  }