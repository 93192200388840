.Trust {
    padding: 7% 0 7% 0;
    height: 100%;
    width: 100%;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(350deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Trust__copyContainer {
    height: 100%;
    width: 30%;
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 5% 0 5% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#tlt_trust {
    width: 100%;
    text-align: center;
    margin: 0 0 3% 0;
}

#cptn1_trust {
    font-size: 2rem;
    margin-bottom: 5%;
}

#inj_trust {
    font-size: 3rem;
    border-bottom: 2px solid #FFFFFF;
    width: 25%;
    height: 44px;
    text-align: center;
    padding-bottom: 1.5%;
    margin-top: 3%;
}

.Trust_btnContainer {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.Trust__numberkey {
    height: 125px;
    width: 125px;
    background-color: transparent;
    border: 4px solid #FFFFFF;
    border-radius: 100px;
    color: #FFFFFF;
    font-size: 3.8rem;
    font-weight: 900;
    margin: 3% 3% 3% 3%;
    transition: .3s ease-in-out;
}

#btn_tr0 {
    margin-left: 37.8%;
}

.Trust__numberkey:hover {
    background-color: #FFFFFF;
    color: #1D6187;
}

.Trust__ctrlContainer {
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-right: 3%;
    padding: 2% 0 2% 0;
}

.Trust__ctrlContainer > :nth-child(1) {
    color: #008800;
    border: 4px solid #008800;
}

.Trust__ctrlContainer > :nth-child(1):hover {
    background-color: #008800;
    color: #FFFFFF;
    border: 4px solid #FFFFFF;
}

.Trust__ctrlContainer > :nth-child(3) {
    color: #F20000;
    border: 4px solid #F20000;
}

.Trust__ctrlContainer > :nth-child(3):hover {
    background-color: #F20000;
    color: #FFFFFF;
    border: 4px solid #FFFFFF;
}

.Trust__ctrl {
    height: 100%;
    width: 100%;
    margin: 3% 0 3% 0;
    padding: 4% 0 4% 0;
    border-radius: 25px;
    border: 4px solid #FFFFFF;
    background-color: #FFFFFF;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 1px;
    transition: .3s ease-in-out;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.Trust__loader {
    height: 100px;
    width: 100px;
    border-left: 4px solid #FFFFFF;
    border-bottom: 4px solid #FFFFFF;
    border-right: 4px solid #FFFFFF; 
    border-radius: 50px;  
    animation: spin 1s linear infinite forwards;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@media only screen and (max-width: 1600px) {
    .Trust {
        flex-direction: column;
    }

    .copyContainer__caption {
        text-align: center;
        line-height: 2.4rem;
    }

    #inj_trust {
        width: 40%;
        text-align: center;
        margin-top: 9%;
    }

    .Trust_btnContainer {
        width: 70%;
    }

    #btn_tr0 {
        margin-left: 38.5%;
    }

    .Trust__ctrlContainer {
        width: 60%;
        margin-top: 5%;
    }
}

@media only screen and (max-width: 600px) {
    .Trust__copyContainer {
        width: 100%;
        height: 100%;
        margin: 0 0 5% 0;
    }
    
    
    .Trust_btnContainer {
        width: 90%;
    }
    
    .Trust__numberkey {
        height: 75px;
        width: 75px;
        font-size: 2.6rem;
    }

    #btn_tr0 {
        margin-left: 39.5%;
    }
}

@media only screen and (max-width: 360px) {
    #btn_tr0 {
        margin-left: 37.5%;
    }
}