.Active {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.Active__copy {
    height: 80%;
    width: 1000%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-bottom: 5%;
}

.copy__title {
    font-size: 5rem;
    font-weight: 800;
    letter-spacing: -1.5px;
    width: 100%;
}

.copy__subtitle {
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 1.5px;
    line-height: 3rem;
    margin-top: 2%;

}


/* loader icon styling from here on out. */

.Active__loader {
    bottom: 0;
    left: 0;
    overflow: hidden;
    right: 0;
    top: 0;
    z-index: 99999;
}

.loader-inner {
    bottom: 0;
    height: 60px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
}

.loader-line-wrap {
    animation: 
		spin 3500ms cubic-bezier(.05, .885, .52, 1) infinite
	;
    box-sizing: border-box;
    height: 50px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform-origin: 50% 100%;
    width: 100px;
}
.loader-line {
    border: 4px solid transparent;
    border-radius: 100%;
    box-sizing: border-box;
    height: 100px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
}
.loader-line-wrap:nth-child(1) { animation-delay: -50ms; }
.loader-line-wrap:nth-child(2) { animation-delay: -100ms; }
.loader-line-wrap:nth-child(3) { animation-delay: -150ms; }
.loader-line-wrap:nth-child(4) { animation-delay: -200ms; }
.loader-line-wrap:nth-child(5) { animation-delay: -250ms; }

.loader-line-wrap:nth-child(1) .loader-line {
    border-color: #ffff78;
    height: 90px;
    width: 90px;
    top: 7px;
}
.loader-line-wrap:nth-child(2) .loader-line {
    border-color: #FFFFFF;
    height: 76px;
    width: 76px;
    top: 14px;
}
.loader-line-wrap:nth-child(3) .loader-line {
    border-color: #ffff78;
    height: 62px;
    width: 62px;
    top: 21px;
}

/*
? if additional lines will be placed in the loading animation 
? then it will follow this format below 

.loader-line-wrap:nth-child(4) .loader-line {
    border-color: #HEX ;
    height: 48px;
    width: 48px;
    top: 28px;
}
.loader-line-wrap:nth-child(5) .loader-line {
    border-color: #HEX ; 
    height: 34px;
    width: 34px;
    top: 35px;
}
*/

@keyframes spin {
    0%, 5% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* * MOBILE RESPONSIVE IMPLEMENTATION * */
@media only screen and (max-width: 1500px) {

}

@media only screen and (max-width: 1300px) {
    .active_subtitle {
        margin-top: 2%;
    }
    .loader-inner {
        bottom: 0;
        height: 60px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 150px;
        width: 100px;
    }
}

@media only screen and (max-width: 1000px) {

}

@media only screen and (max-width: 800px) {
    .copy__subtitle {
        font-size: 1.6rem;
        margin-top: 7%;
    }
}

@media only screen and (max-width: 600px) {

}