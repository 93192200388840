.Checkout {
    height: 300px;
    width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 7%;
}

#ClearentPayButtonContainer {
    height: 30%;
    width: 40%;
    background-color: none;
    border-radius: 60px;
}

#Clearent-pay-now {
    height: 60%;
    width: 90%;
    background-color: #008000;
    border: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 800;
    font-size: 3rem;
    letter-spacing: 1px;
    transition: .2s ease-in-out;
    border-radius: 20px;
    border: .5px dashed #FFFFFF;
    color: #FFFFFF;
    transition: .3s ease-in-out;
}

#Clearent-pay-now:hover {
    background-color: #EEAECA;
    box-shadow: .25px .25px 10px .5px #FFFFFF;
}


#Clearent-modal {
    background: rgb(255,255,255);
    background: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
    box-shadow: .25px .25px 10px .5px #FFFFFF;
    border-radius: 10px;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#Clearent-modal > :nth-child(3) {
    border-bottom: none;
}

#Clearent-modal > :nth-child(5) {
    border-top: none;
}

#ClearentModalLabel {
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.Clearent-header > button > span {
    color: #FFFFFF;
    opacity: 100%;
}

.Clearent-header > :nth-child(2) {
    opacity: 100%;
}

.loader_tip {
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    height: 20%;
    width: 100%;
    text-align: center;
    text-justify: center;

}


/* ? loading bar */
.loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader__aniFrame {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70%;
}


.loader .loading_1 {
    position: relative;
    width: 70%;
    height: 10%;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    animation: turn 3s ease-in-out infinite;
}

.loader .loading_1:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 10px 0px 15px 0px #FFFFFF;
    animation: load 3s ease-in-out infinite;
    border-radius: 7px;
}

@keyframes load {
    0% {
        width: 0%;
    }

    87.5% {
        width: 100%;
    }
}

/* .loading_1__text {
    color: #FFFFFF;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    padding: 2% 0 2% 0;
} */

.loading_bankIcon {
    background-image: url("../../assets/icons/bank.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;
    width: 100px
} 

.loading_homeIcon {
    background-image: url("../../assets/icons/website.svg");
    background-size: contain;
    background-repeat: no-repeat;background-position: center;
    height: 100px;
    width: 100px;
}


/* * MOBILE RESPONSIVE IMPLEMENTATION * */
@media only screen and (max-width: 1500px) {

}

@media only screen and (max-width: 1300px) {
    .Checkout {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 1000px) {
    .Checkout {
        height: 600px;
    }

    .loading_homeIcon {
        height: 200px;
        width: 200px;
        margin: 2% 0 3% 0

    }

    .loading_bankIcon {
        height: 200px;
        width: 200px;
        margin: 3% 0 2% 0;
    }

    .loader__aniFrame {
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .loader .loading_1 {
        height: 100%;
        width: 5%;
    }

    .loader .loading_1:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 0;
        background-color: #FFFFFF;
        box-shadow: none;
        animation: load 3s ease-in-out infinite;
        border-radius: 7px;
    }
    
    @keyframes load {
        0% {
            height: 0%;
        }
    
        87.5% {
            height: 100%;
        }
    }

}

@media only screen and (max-width: 800px) {

}

@media only screen and (max-width: 600px) {

}