.NewHelp {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(350deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #FFFFFF;
}

.NewHelp__welcome {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 5% 0 5% 5%;
}

.welcome__title {
    font-size: 4.6rem;
    font-weight: 800;
}

.welcome__subtitle {
    font-size: 2.8rem;
    width: 40%;
    line-height: 3.2rem;
    font-style: italic;
    margin: 2% 0 2% 0;
}

.welcome__copy {
    font-size: 2rem;
    width: 55%;
    line-height: 2.8rem;
    margin: 1% 0 1% 0;
}

#warn_welcomenh {
    padding: 1% 1% 1.2% 1%;
    background: #F20000;
    border-radius: 15px;
}

.menu__link {
    text-decoration: none;
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
    color: #1D6187;
    padding: 7% 0 9% 5%;
    border-top: 5px dashed #FFFFFF;
    transition: .3s ease-in-out;
    opacity: 90%;
}

.menu__link:hover {
    background-color: #1D6187;
    opacity: 90%;
}

.menu__link:hover > :first-child {
    color: #FFFFFF;
}

.menu__link:hover > :nth-child(2) {
    color: #FFFFFF;
}

#link_NHtitle {
    font-size: 5rem;
    margin-bottom: 2%;
    font-weight: 800;
    transition: .3s ease-in-out;
}

#link_NHdesc {
    font-size: 2.4rem;
    width: 55%;
    font-weight: 200;
    line-height: 2.8rem;
    font-style: italic;
    transition: .3s ease-in-out;
}

@media only screen and (max-width: 1024px) {
    .welcome__title {
        font-size: 3rem;
    }
    .welcome__subtitle {
        font-size: 2rem;
        width: 60%;
    }
    .welcome__copy {
        font-size: 1.6rem;
    }
    #warn_welcomenh {
        padding: 3% 3% 3% 3%;
    }
    #link_NHtitle {
        font-size: 3rem;
    }
    #link_NHdesc {
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 600px) {
    .welcome__title {
        line-height: 3.6rem;
    }
    .welcome__subtitle {
        width: 90%;
    }
    .welcome__copy {
        width: 90%;
        margin: 5% 0 5% 0;
    }
    #warn_welcomenh {
        padding: 5% 5% 5% 6%;
    }
    #link_NHtitle {
        font-size: 2.4rem;
        text-decoration: underline;
    }
    #link_NHdesc {
        width: 90%;
        margin-top: 7%;
        margin-left: 1%;
    }
}