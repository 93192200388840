.ServicesList {
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-image: url("../../assets/textures/texture3_black&purp.svg");
    background-size: cover;
    transition: .2s ease-in-out;
}

.ServicesList__link {
    /* border: 2px solid green; */
    border: 10px solid #FFFF78;
    height: 100%;
    width: 80%;
    text-decoration: none;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 1000;
    font-size: 6rem;
    color: #FFFF78;
    margin: 2% 0 2% 0;
    border-radius: 15px;
    transition: .2s ease-in-out;
}

#service_commercial {
    background-image: url("../../assets/HP_gallery/HPGal_2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top 20% left 0%;
}

#service_residential {
    background-image: url("../../assets/HP_gallery/HPGal_12.JPG");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

#service_brands {
    background-image: url("../../assets/img/BRANDS.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.ServicesList__service {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    transition: .2s ease-in-out;
}

.ServicesList__service > h1 {
    padding: 7.5% 0 7.5% 0;
    transition: .2s ease-in-out;
}

.ServicesList__link:hover > div > h1 {
    color: #0B72B6;
}

.ServicesList__link:hover {
    border: 10px solid #0B72B6;
}

.ServicesList__link:focus > div > h1 {
    color: #0B72B6;
}

.ServicesList__link:focus {
    border: 10px solid #0B72B6;
}

@media only screen and (max-width: 1024px) {
    .ServicesList {
        flex-direction: column;
        justify-content: space-between;
    }
    .ServicesList__service > h1 {
        font-size: 4rem;
        text-align: center;
        padding-bottom: 9%;
    }
    .ServicesList__service {
        background-color: #1E132A;
    }
    .ServicesList > :last-child {
        margin-bottom: 7%;
    }
    .ServicesList__link {
        margin-bottom: 5%;
    }
    .ServicesList__service {
        border-radius: 5px;
        line-height: 3.5rem;
        padding-top: 3%;
        
    }
}

@media only screen and (max-width: 800px) {
    .ServicesList__service > h1 {
        font-size: 3rem;
        padding-bottom: 11%;
    }


}

@media only screen and (max-width: 600px) {
    .ServicesList__service > h1 {
        font-size: 3rem;
        text-align: center;
    }
    .ServicesList__service {
        background-color: #1E132A;
    }
    .ServicesList__link {
        margin-bottom: 5%;
    }
    .ServicesList__service {
        border-radius: 5px;
        line-height: 3.5rem;
        padding-top: 3%;
        
    }
}