.TOBMenu {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(350deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
}

.TOBMenu__copyContainer {
    margin: 3% 0 3% 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#title_tobintro {
    font-size: 7rem;
    font-weight: 900;
    padding-left: 5%;
}

#subtitle_tobintro {
    font-size: 3rem;
    font-style: oblique;
    font-weight: 600;
    padding-left: 7%;
    padding-top: .75%;
}

#copy_tobintro {
   font-size: 2rem;
   padding-top: 3%;
   padding-left: 5%;
   width: 60%;
   line-height: 2.6rem;
}

.TOBMenu__guideContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.guideContainer__link {
    text-decoration: none;
    height: 100%;
    width: 100%;
    transition: .4s ease-in-out;
    opacity: 90%;
}

.guideContainer__guideItem {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px dashed #FFFFFF;

    font-family: 'Courier New', Courier, monospace;
    color: #FFFFFF;
    transition: .4s ease-in-out;
}

.guideContainer__link:hover {
    background-color: #FFFFFF;
    color: #1D6187;
    opacity: 90%;
}

.guideContainer__link:hover > div {
    color: #1D6187;
}

/* hot fix for the bottom border at the end of the list */
#TOB_RNG {
    border-bottom: 1px dashed #FFFFFF;
}


.guideItem__title {
    height: 100%;
    width: 80%;
    font-size: 5rem;
    margin-left: 5%;
    font-weight: 600;
}

.guideItem__subtitle {
    font-size: 2rem;
    width: 80%;
    padding-left: 5%;
    margin-top: 3%;
    line-height: 2.6rem;
}

.guideItem__photo {
    height: 100%;
    width: 40%;
}

@media only screen and (max-width: 1025px) {
    #subtitle_tobintro {
        font-size: 2.4rem;
    }
    .guideItem__title {
        width: 80%;
    }
    .guideItem__photo {
       display: none; 
    }
    .guideContainer__guideItem {
        padding: 7% 5% 7% 0%;
    }
    #copy_tobintro {
        text-align: start;
        display: block;
    }
}




@media only screen and (max-width: 600px) {
    .TOBMenu__copyContainer {
        margin-bottom: 12%;
        margin-top: 7%;
    }
    #title_tobintro {
        font-size: 5rem;
    }

    #subtitle_tobintro {
        font-size: 2rem;

    }

    #copy_tobintro {
        width: 96%;
        font-size: 1.6rem;
    }
    
    .guideItem__title {
        text-decoration: underline;
        font-size: 3rem;
        width: 100%;
    }

    .guideItem__copyContainer {
        background-color: #FFFFFF;
        color: #1D6187;
        padding: 5% 0 5% 0;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
    }
}
