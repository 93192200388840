.HiringNotif {
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    flex-direction: center;
    border: 6px solid transparent;
    background-color: #FFFF78;
    border-radius: 15px;
    box-sizing: border-box;
    transition: .3s ease-in-out;
    margin: 2% 0 2% 0;
}

.HiringNotif__link {
    height: 100%;
    padding: 7% 0 7% 0;
    width: 100%;
    text-align: center;
    font-size: 3rem;
    text-decoration: none;
    color: #000000;
    font-weight: 900;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    transition: .3s ease-in-out;
}

.HiringNotif:hover {
    background-color: transparent;
    border-color: #ffffff;
}

.HiringNotif:hover > a {
    color: #ffffff;
}



@media only screen and (max-width: 1024px) {
    .HiringNotif {
        width: 80%;
        margin: 5% 0 5% 0;
    }
}