.TOBMarketing {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #B5333A;
}

.TOBMarketing__copyContainer {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 7% 0 7% 5%;

    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #FFFFFF;
}

.copyContainer__title {
    font-size: 6rem;
    padding: 0 0 2% 0;
    font-weight: 800;
    height: 100%;
    width: 96%;
}

.copyContainer__subtitle {
    font-size: 2.6rem;
    height: 100%;
    font-style: oblique;
    padding-bottom: 4%;
    font-weight: 200;
    font-family: 'Courier New', Courier, monospace;
}

.copyContainer__copy {
    font-size: 2rem;
    width: 60%;
    height: 100%;
    line-height: 2.4rem;
}


.TOBMarketing__vidContainer {
    height: 460px;
    width: 40%;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 0 0;
    margin-right: 4%;
}

.vidContainer__iframe {
    height: 100%;
    width: 100%;
}

.copyContainer__logoContainer {
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 2% 0;
    padding: 3% 2% 3% 2%;
}

.logoContainer__icon {
    height: 100px;
    width: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 65%;
}

#mktgicon_sony {
    background-image: url(../../assets/img/TOB/logo-sony.png);

}

#mktgicon_sonos {
    background-image: url(../../assets/img/TOB/Sonos_Logo.png);

}

#mktgicon_eero {
    background-image: url(../../assets/img/TOB/eerologo.png);
}

@media only screen and (max-width: 1025px) {
    .TOBMarketing {
        flex-direction: column;
    }
    
    .TOBMarketing__copyContainer {
        width: 98%;
        text-align: center;
        padding: 5% 0 7% 0;
    }

    .copyContainer__logoContainer {
        width: 96%;
        margin-left: 2%;
    }

    .copyContainer__subtitle {
        width: 100%;
    }

    .copyContainer__copy {
        width: 96%;
    }

    .TOBMarketing__vidContainer {
        width: 100%;
        margin-right: 0;
        border: none;
        border-top: 2px dashed #FFFFFF;
    }
    
    .logoContainer__icon {
        width: 120px;
    }
}

@media only screen and (max-width: 600px) {
    .copyContainer__logoContainer {
        width: 96%;
    }

    .logoContainer__icon {
        width: 80px;
    }
}