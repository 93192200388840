.TrustNav {
    padding: 4% 0 4% 0;
    height: 100%;
    width: 100%;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(350deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.TrustNav__link {
    border: 4px solid #FFFFFF;
    height: 100%;
    width: 70%;
    padding: 5% 0 5% 0;
    margin: 3% 0 5% 0;
    text-align: center;
    text-decoration: underline;
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    border-radius: 25px;
    transition: .3s ease-in-out;
}

.TrustNav__link:hover {
    background-color: #FFFF78;
    color: #1D6187;
    border: 4px solid #1D6187;
}

.TrustNav__link:focus {
    background-color: #FFFF78;
    color: #1D6187;
    border: 4px solid #1D6187;
}

@media only screen and (max-width: 800px) {
    .TrustNav__link {
        font-size: 2rem;
        padding: 9% 0 9% 0;
        width: 90%;
        margin: 5% 0 9% 0;
    }
}
