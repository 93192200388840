.Copyright {
    height: 100%;
    width: 100%;
    /* background-color: #80BCD8; */
    background-image: url("../assets/textures/texture3_blue&white.svg");
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.Copyright__logo {
    /* border: 2px solid red; */
    background-image: url("../assets/icons/audio-contractors-new-logo-with-llc-no-tag-line.svg");
    height: 100px;
    width: 25%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 1.5% 0 2% 0;
    
}

.Copyright__copyContainer {
    /* border: 2px solid red; */
    height: 100%;
    width: 65%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-items: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: 2rem;
    color: #FFFFFF;
    font-weight: 800;
    text-align: center;
    margin: 1% 0 1% 0;
}

#claim {
    font-style: oblique;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 2rem;
    width: 45%;
    text-align: end;
    color: rgb(255, 255, 120);
}

#claim > a {
    padding: 2% 3% 2% 3%;
    background-color: rgb(255, 255, 120);
    text-decoration: underline;
    border-radius: 5px;
    color: #1E132A;
    transition: .3s ease-in-out;
    letter-spacing: 1px;
}

#claim:hover > a {
    color: #EEAECA;
}

#disclaim {
    padding: 2.5% 5% 2% 5.5%;
    font-weight: 1000;
    letter-spacing: 1px;
}

@media only screen and (max-width: 1500px) {
    .Copyright__copyContainer {
        width: 100%;
        justify-content: space-between;
        padding: 2% 1% 2% 0;

    }
    #claim {
        line-height: 3rem;
    }
    #disclaim {
        padding: 2.5% 5% 2.5% 2%;
    }
    .Copyright__logo {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .Copyright__copyContainer {
        width: 100%;
        padding: 2% 1% 2% 1%;
    }
    #disclaim {
        font-size: 1.2rem;
        padding: 0 0 0 2%;
        width: 45%;
        line-height: 1.5rem;        
    }
    #claim {
        font-size: 1rem;
        width: 50%;
        line-height: 2.2rem;
        padding: 2% 2% 2% 0;
        font-weight: 600;
    }

}



