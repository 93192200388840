.VerifyForm {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: .7s ease-in-out;
}

.VerifyForm__title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 6rem;
    font-weight: 600;
    margin: 0 0 1% 0;
    color: #FFFFFF;
    height: 100%;
    width: 100%;
    text-align: center;
}


.VerifyForm__frame {
    margin: 2% 0 2% 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.VerifyForm__label {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #FFFFFF;
    font-size: 2.2rem;
    padding-bottom: 1.5%;
}

.VerifyForm__input {
    width: 25%;
    height: 100%;
    padding: 1% .5% 1% .5%;
    border-radius: 5px;
    border: 1px solid #FFFFFF;
    color: #000000;
}


.VerifyForm__btnContainer {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 1% 0 2% 0;
    margin-top: 3%;
}

.VerifyForm__section {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2% 0 2% 0;
    margin: 1% 0 1% 0;
}

.section__button { 
    height: 100%;
    width: 35%;
    padding: 2% 0 2% 0;
    margin: 2% 0 2% 0;
    border-radius: 25px;
    border: 1px dashed #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 800;
    font-size: 2rem;
    color: #FFFFFF;
    transition: .3s ease-in-out;
}

.section__buttonContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#btn_cardtypeY {
    background-color: #7F84A3;
    border: 2px solid #FFFFFF;
    font-size: 3rem;
}

#btn_cardtypeN {
    background-color: #7F84A3;
    border: 2px solid #FFFFFF;
    font-size: 3rem;
}

#btn_cardtypeY:hover {
    background-color: #008000;
}

#btn_cardtypeN:hover {
    background-color: #008000;
}

#btn_next {
    background-color: #0B72B6;
}

#btn_next:hover {
    background-color: #008000;
    text-decoration: underline;
}

#btn_help {
    background-color: #7F84A3;
    color: #FFFFFF;
    opacity: 70%;
}


#btn_help:hover { 
    background-color: #FFFFFF;
    color: #7F84A3;
    border: 1px solid #7F84A3;
    text-decoration: underline;
    opacity: 100%;
}

.VerifyForm__restart {
    height: 100%;
    width: 15%;
    padding: 1% 0 1% 0;
    margin: 17% 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border: 2px solid #FFFFFF;
    border-radius: 15px;
    background-color: #F20000;
    color: #FFFFFF;
    font-weight: 800;
    opacity: 40%;
    transition: .3s ease-in-out;
}

#img_restart {
    margin-right: 5%;
}

.VerifyForm__restart:hover {
    opacity: 100%;
}

.VerifyForm__caption {
    font-size: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #FFFFFF;
    margin-top: 2%;
    text-align: center;
}

#cpy_creditnotice {
    width: 50%;
    text-align: center;
    line-height: 2.4rem;
    padding: 1.5% 1.5% 1.7% 1.5%;
    background-color: #F20000;
    border-radius: 25px;
    border: 5px solid #FFFFFF;
    opacity: 90%;
    margin-bottom: 3%;
}

/* * MOBILE RESPONSIVE IMPLEMENTATION * */
@media only screen and (max-width: 1500px) {

}

@media only screen and (max-width: 1300px) {
    .VerifyForm__input {
        margin: 2% 0 2% 0;
    }

    .VerifyForm__restart {
        width: 25%;
        padding: 2.5% 0 2.5% 0;
    }
    .VerifyForm__input {
        padding: 3% 0 3% 2%;
        font-size: 2rem;
        margin-top: 2%;
    }
    .VerifyForm__label {
        font-size: 3.6rem;
    }
    #cpy_creditnotice {
        width: 80%;
        margin: 3% 0 3% 0;
        font-size: 2rem;
    }
}

@media only screen and (max-width: 1000px) {
    .VerifyForm__title {
        margin-bottom: 7%;
    }

    .VerifyForm__input {
        width: 75%;
        padding: 3% .5% 3% .5%;
        margin: 3% 0 3% 0;
    }
    .VerifyForm__label {
        text-align: center;
        font-size: 2.8rem;
    }

    #cpy_creditnotice {
        font-size: 1rem;
        line-height: 1.6rem;
    }
}

@media only screen and (max-width: 800px) {
    .VerifyForm__input {
        padding: 7% 0 7% 5%;
        font-size: 2rem;
        margin-top: 7%;
    }

    .VerifyForm__label {
        font-size: 2.8rem;
        width: 90%;
        line-height: 3.2rem;
    }
    
    .VerifyForm__button { 
        width: 100%;
        padding: 8% 0 9% 0;
    }
    
    .VerifyForm__restart {
        width: 60%;
        margin-top: 22%;
        padding: 5% 0 5% 0;
    }
    
    .section__button {
        width: 80%;
        margin-top: 9%;
        padding: 7% 0 7% 0;
    }

    #cpy_creditnotice {
        padding: 5% 7% 5% 7%;
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 9%;
    }

    .section__buttonContainer {
        flex-direction: column;
    }

}

@media only screen and (max-width: 600px) {

}