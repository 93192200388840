.RSS {
    border-top: 15px ridge #000000;
    /* background-image: url("../../assets/textures/rings_1.svg");
    background-size: contain;
    background-position: top; */
}
.powr-social-feed {
    /* padding: 1% 0 5% 0; */
    background-color: #000000;
    padding: 0 2% 4% 2%;

}

.psf__title {
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 5rem;
    font-weight: 960;
    font-style: oblique;
    letter-spacing: 4px;
    text-align: center;
    padding-top:3%;
}

@media only screen and (max-width: 1500px) {
    .powr-social-feed {
        padding: 1% .5% 5% .5%;
    }
}

@media only screen and (max-width: 1024px) {
    .powr-social-feed {
        padding: 5% 0 2% 0;
    }
}