.KnowledgeHome {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(350deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
}

.KnowledgeHome__introContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    color: #ffffff;
}

.introContainer__title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 6rem;
    font-weight: 800;
    padding: 0 0 0 3%;
    margin: 2% 0 2% 0;
}

.introContainer__caption  {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    height: 100%;
    width: 80%;
    padding: 0 0 0 3%;
    line-height: 3.4rem;
}

.introContainer__email {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    height: 100%;
    width: 80%;
    padding: 0 0 0 3%;
    margin: 2% 0 2% 0;
}

#KH_emailink {
    color: #ffffff;
    transition: .2s ease-in-out;
}

#KH_emailink:hover {
    color: #EEAECA;
}

.KnowlegeHome__KHcontainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.KHContainer__listItem {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3.4% 3% 4% 3%;
    text-decoration: none;
    transition: .3s ease-in-out;
    border-bottom: .5px solid #ffffff;
}

#KHlist_1 {
    border-top: .5px solid #FFFFFF;
}

.topic__title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    font-weight: 1000;
    transition: .3s ease-in-out;
    color: #FFFFFF;
    letter-spacing: 2px;
}

.listitem__arrow {
    color: #ffffff;
    font-size: 5rem;
}

.KHContainer__listItem:hover {
    background-color: #FFFF78;
}

.KHContainer__listItem:hover > p {
    color: #1D6187;
    text-decoration: none;
}

@media only screen and (max-width: 1600px) {

}


@media only screen and (max-width: 1240px) {
    .introContainer__title {
        font-size: 3rem;
    }
}


@media only screen and (max-width: 800px) {
    .introContainer__title {
        font-size: 2.4rem;
        padding: 3% 0 3% 3%;
    }

    .introContainer__caption {
        font-size: 2rem;
        line-height: 2.4rem;
        width: 95%;
    }

    .introContainer__email {
        font-size: 1.4rem;
        margin-top: 7%;
    }
    .KHContainer__listItem {
        padding: 15.4% 5% 15% 3%;
    }

    /* !prevents titles from disappearing at media breakpoint from other comp stylings */
    .KHContainer__listItem > p {
        display: block;
        font-weight: 400;
        font-size: 2rem;
    }
    /* ! */

    .listitem__arrow {
        font-size: 3rem;
    }

    .topic__title {
        font-size: 2rem;
        line-height: 2.4rem;
    }

    #KHlist_title_1 {
        width: 60%;
        line-height: 2.8rem;
    }
    #KHlist_title_5 {
        width: 60%;
        line-height: 2.8rem;
    }
}


@media only screen and (max-width: 700px) {

}

