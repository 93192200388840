.TeamIntro {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TeamIntro__titleContainer {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 35%;
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 6% 0% 6% 0%;    
}

.titleContainer__title {
    color: #0B72B6;
    font-size: 4.2rem;
    font-weight: 1000;
    letter-spacing: 3px;
    width: 80%;
}

.titleContainer__caption {
    width: 80%;
    height: 100%;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    padding-left: 1%;
    margin-top: 2%;
    letter-spacing: .5px;
}

.TeamIntro__copyContainer {
    /* border: 2px solid red; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 4.10% 0 4.10% 5%;
}

.TeamIntro__copyContainer > h3 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 4rem;
    font-weight: 1000;
    letter-spacing: 2px;
    margin-bottom: 1.5%;
}

.TeamIntro__copyContainer > p {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.75rem;
    line-height: 2rem;
    width: 80%;
    font-weight: 400;
    padding-left: 1%;
    padding-bottom: 1.25%;
}

.bullet {
    padding-right: 1%;
}

#team_parEmph {
    font-style: italic;
}

.color {
    color: #0B72B6;
}


@media only screen and (max-width: 1024px) {
    .TeamIntro {
        flex-direction: column;
    }
    .TeamIntro__titleContainer {
        width: 100%;
    }
    .TeamIntro__copyContainer {
        width: 100%;
        padding: 4.01% 0 4.03% 10%;
    }
    .TeamIntro__copyContainer > p {
        padding-bottom: 3%;
    }

}

@media only screen and (max-width: 600px) {
    .TeamIntro {
        flex-direction: column;
    }
    .TeamIntro__titleContainer {
        width: 100%;
    }
    .TeamIntro__copyContainer {
        width: 100%;
        padding: 5% 0 5% 0;
        align-items: center;
    }
    .TeamIntro__copyContainer > p {
        width: 90%;
        font-size: 1.6rem;
        margin-bottom: 3%;
    }
    #team_subtitle {
        width: 100%;
        text-align: start;
        padding-left: 5%;
    }
    #team_parEmph {
        font-weight: 600;
    }
}