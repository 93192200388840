.LANform {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(350deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
    padding: 1% 0 3% 0;
    transition: .4s ease-in-out;
}

.lanform__subheaderContainer {
    height: 100%;
    width: 90%;
    text-align: center;
    color: #FFFFFF; 
    margin: 1% 0 2% 5%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
}

.subheaderContainer__header  {
    font-size: 5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 800;
    letter-spacing: 1px;
}

.subheaderContainer__caption {
    font-size: 2rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    margin: 1% 0 1% 0;
    width: 50%;
    text-align: start;
    line-height: 2.4rem;
}

.lanform__netContainer {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 2% 5% 5% 5%;
}

.netContainer__inputContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2% 0 2% 0;
}

.guestContainer__inputContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2% 0 2% 0;
}

.lanform__lable {
    color: #FFFFFF;
    font-family: 'Courier New', Courier, monospace;
    font-size: 3rem;
}

.lanform__box {
    height: 50px;
    width: 50px;
}

.lanform__input {
    height: 75px;
    width: 350px;
    border: 1.5px solid #1E132A;
    border-radius: 5px;
    transition: .3s ease-in-out;
    padding-left: 1%;
}

.lanform__input:focus {
    box-shadow: 5px 4px #EEAECA;
}

/* hot fix to add the caption to the passcode locations */
#label_specialWifi {
    font-size: 1.2rem;
}

/* little trick for the form appearance, no special relevance or correction. */
#GuestNetworkName:focus {
    box-shadow: 5px 4px #1d6187;
}
#guestpasscode:focus {
    box-shadow: 5px 4px #1d6187;
}
#NetworkName:focus {
    box-shadow: 5px 4px #1d6187;
}
#passcode:focus {
    box-shadow: 5px 4px #1d6187;
}
/* end CSS trick */

#lable_guestCheck {
    line-height: 3.2rem;    
}

.lanform__guestContainer {
    height: 100%;
    width: 100%;
}

.listContainer__instructions {
    color: #FFFFFF;
    font-family: 'Courier New', Courier, monospace;
    font-size: 2.4rem;
    margin-top: 2%;
}

.lanform__listContainer {
    margin-top: 2%;
    border-top: 2px dashed #FFFFFF;
    transition: .4s ease-in-out;
}

.lanform__errors {
    /* border: 1px solid blue; */
    height: 100%;
    width: 65%;
    margin: 1% 0 1% 3%;
    padding: 2% 0 2% 2%;
    background-color: #FFFFFF;
    border: 10px solid #f20000;
    border-radius: 25px;
    opacity: 96%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.lanform__errors > p {
    font-size: 2.4rem;
    font-weight: 800;
    color: #F20000;
    margin-bottom: 3.2%;
    letter-spacing: 1.5px;
}

.lanform__errors > ul > li {
    font-size: 2rem;
    padding: 0 0 2% 3%;
    color: #F20000;
}

#submit_lanform {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: #1d6187;
    color: #FFFFFF;
    border-radius: 5px;
    height: 125px;
    width: 400px;
    transition: .2s ease-in-out;
    margin: 12% 0 0 0;
}

input[type="submit"]:hover {
    background-color: #008000;
}

#form_lanform {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1024px) {
    .lanform__netContainer {
        width: 100%;
    }
    .netContainer__inputContainer {
        flex-direction: column;
    }
    .lanform__lable {
        margin-bottom: 2%;
        margin-top: 5%;
    }
    .lanform__subheaderContainer {
        align-items: center;
    }
    .subheaderContainer__caption {
        width: 80%;
        margin-top: 3%;
    }
    #submit_lanform {
        width: 350px;
    }
}

@media only screen and (max-width: 800px) {
    #lable_guestCheck {
        font-size: 2rem;
        margin-top: 12%;
    }
    .listContainer__instructions {
        font-size: 2rem;
        line-height: 2.4rem;
    }
    .lanform__errors {
        width: 90%;
    }
}

@media only screen and (max-width: 500px) {
    .subheaderContainer__caption {
        width: 96%;
        margin-top: 7%;
    }
}
