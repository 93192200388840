.TopicFrame {
    background-color: #FFFFFF;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}

.TopicFrame__materialframe {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-decoration: none;
}

.TopicFrame__title {
    font-family: 'Courier New', Courier, monospace;
    font-size: 3rem;
    padding: 0 0 0 3%;
    margin: 3% 0 2% 0;
}

.bulletcontainer__bullet {
    margin: 1% 0 1% 0;
}


.materialframe__bulletcontainer {
    font-size: 2rem;
    line-height: 2.4rem;
    padding: 0 0 0 3%;
    margin: 3% 0 0 0;
    width: 70%;
}

.materialframe__linkgroup {
    font-size: 3rem;
    padding: 0 0 0 3%;
    margin: 3% 0 0 0;
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.linkgroup__list {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    border-radius: 50px;
    margin: 3% 0 3% 0;
    transition: .3s ease-in-out;
}


.glink__link:hover {
    background-color: #FFFFFF;
    
}


.glink__link {
    color: #FFFFFF;
    text-decoration: none;
    transition: .3s ease-in-out;
    width: 50%;
    margin: 1% 0 1% 0;
    text-align: center;
    padding: 2% 0 2% 0;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(350deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
    border-radius: 25px;
    transition: .3s ease-in-out;
}

.materialframe__calloutcontainer {
    height: 20%;
    width: 80%;
    font-size: 2rem;
    line-height: 2.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 2.5% 3% 2.5% 3%;
    background-color: #ffff78;
    color: #000000;
    border-top-right-radius: 75px;
    border-bottom-right-radius: 75px;
}

.materialframe__subsection {
    height: 100%;
    width: 80%;
    margin: 3% 0 3% 0;
    padding: 0 0 0 3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    
}

.subsection__title {
    font-size: 3rem;
}

.subsection__materialcontent {
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 1% 0 1% 0;
}

.materialframe__img {
    height: 100%;
    width: 70%;
    margin: 3% 0 3% 3%;
}

@media only screen and (max-width: 1600px) {

}


@media only screen and (max-width: 1240px) {

}


@media only screen and (max-width: 800px) {
    .materialframe__bulletcontainer {
        width: 90%;
        margin-bottom: 5%;
    }
    .bulletcontainer__bullet {
        width: 100%;
        margin: 5% 0 5% 0;
    }
    .materialframe__linkgroup {
        width: 90%;
        
    }
    .glink__link {
        width: 100%;
        padding: 3% 0 4% 0;
        margin-bottom: 5%;
    }
    .materialframe__subsection {
        width: 90%;
    }
}


@media only screen and (max-width: 700px) {

}