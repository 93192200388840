.SupportMenu {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
}

.SupportMenu__container {
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    padding: 2% 0 1% 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    color: #ffffff;
    text-decoration: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.comCust_container {
    /* border: 2px solid red; */
    width: 45%;
    height: 100%;
    padding: 1.5% 0 1% 1%;
    font-weight: 400;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

#label_comCust {
    width: 90%;
}

.newTicket__box {
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    margin: 2% 0 2% 0;
}

.SMcontainer__title {
    font-size: 4rem;
    font-weight: 900;
    margin-bottom: 1%;
}

.SMcontainer__copy {
    font-size: 2rem;
    margin-bottom: 3%;
    width: 60%;
}

.SMcontainer__subcopy {
    font-size: 2.5rem;
    margin-bottom: 4%;
    font-weight: 900;
}

.SMcontainer__link {
    text-decoration: none;
    font-size: 3rem;
    color: #FFFFFF;
    background-color: transparent;
    font-weight: 900;
    padding: 2% 2% 2% 2%;
    margin-bottom: 2%;
    border: 2px solid #ffffff;
    width: 20%;
    text-align: center;
    border-radius: 25px;
    transition: .3s ease-in-out;
}

.SMcontainer__link:hover {
    color: #0B72B6;
    background-color: #ffffff;
    border-color: transparent;
    background-color: #FFFFFF;
}

#cntnr_sm2 > a {
    color: #ffffff;
    text-decoration: none;
    font-size: 2rem;
    margin-bottom: 4%;
}


.SMcontainer__information {
    /* border: 1px solid green; */
    width: 50%;
    font-size: 3rem;
}

#icon_map {
    /* border: 2px solid red; */
    height: 40px;
    width: 40px;
    padding: 2% 7% 2% 0%;
    background: url("../../assets/icons/map.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 2%;
}

#icon_phone {
    padding: 2% 7% 2% 0%;
    height: 40px;
    width: 40px;
    margin: 0 1% 0 0;
    background: url("../../assets/icons/phone.svg");
    background-repeat: no-repeat;
}

#icon_email {
    padding: 2% 7% 2% 0%;
    margin: 0 1% 0 0;
    height: 40px;
    width: 40px;
    background: url("../../assets/icons/mail.svg");
    background-repeat: no-repeat;
    background-position: center;
}


@media only screen and (max-width: 1500px) {
    .SMcontainer__information {
        width: 90%;
        margin-bottom: 7%;
    }

    .SMcontainer__copy {
        line-height: 120%;
    }

    .SMcontainer__subcopy {
        margin-bottom: 8%;
        line-height: 120%;
    }
    .SupportMenu__container {
        padding: 5% 5% 5% 5%;
    }
}

@media only screen and (max-width: 1200px) {
    .SMcontainer__link {
        width: 40%;
    }
    .SMcontainer__information {
        font-size: 1.6rem;
    }
    .SMcontainer__copy {
        width: 90%;
    }
}

@media only screen and (max-width: 800px) {
    .SMcontainer__link {
        margin-top: 4%;
        margin-bottom: 4%;
        width: 100%;
        padding-top: 7%;
        padding-bottom: 7%;
    }
    .SMcontainer__information {
        text-align: center;
        width: 100%;
    }
    .SMcontainer__subcopy {
        margin-bottom: 11%;
        text-align: center;
        font-size: 1.5rem;
    }
    .SMcontainer__copy {
        margin-top: 5%;
    }

    .comCust_container {
        /* border: 2px solid red; */
        width: 98%;
        flex-direction: column-reverse;
        align-items:flex-start;
        justify-content: space-between;
        padding: 3% 0 3% 3%;
    }

    .newTicket__box {
        -ms-transform: scale(1.25); /* IE */
        -moz-transform: scale(1.25); /* FF */
        -webkit-transform: scale(1.25); /* Safari and Chrome */
        -o-transform: scale(1.25); /* Opera */
        transform: scale(1.25);
        padding: 10px;
        margin: 1% 0 3% 0;        
    }
}