.Processing {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20% 0;
}



/* * MOBILE RESPONSIVE IMPLEMENTATION * */
@media only screen and (max-width: 1500px) {

}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1000px) {

}

@media only screen and (max-width: 800px) {
    .Processing {
        margin: 0 0 50% 0;
    }
}

@media only screen and (max-width: 600px) {

}