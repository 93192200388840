.WindowFrame {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0px .05px 20px .05px #ffffff;

    background: rgb(255,255,255);
    background: -moz-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(350deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
    padding: 8% 0 12% 0 ;
    
}

@media only screen and (max-width: 1500px) {
    .WindowFrame {
        padding: 10% 0 20% 0;
    }
}

@media only screen and (max-width: 1300px) {
    .WindowFrame {
        padding: 12% 0 16% 0;
    }
}

@media only screen and (max-width: 1000px) {
    .WindowFrame {
        padding: 18% 0 18% 0;
    }
}

@media only screen and (max-width: 800px) {
    .WindowFrame {
        padding: 20% 0 20% 0;
    }
}

@media only screen and (max-width: 600px) {
    .WindowFrame {
        padding: 25% 0 25% 0;
    }
}
