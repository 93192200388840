.Ring {
    height: 100%;
    width: 100%;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(350deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.Ring__guide {
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5% 0 0 0;
}

.guide__viewContainer {
    height: 100%;
    width: 30%;
    padding: 15% 0 15% 0;
    transition: .6s ease-in-out;
}

#viewer_rng_0 {
    background-image: url("../../../assets/img/TOB/ring_logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

#viewer_rng_1 {
    background-image: url("../../../assets/img/TOB/ring_product.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

#viewer_rng_2 {
    background-image: url("../../../assets/img/TOB/ringQR.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}


.guide__captions {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 5%;
}

.captions__title {
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 4rem;
    font-weight: 800;
    line-height: 2.4rem;
    width: 100%;
    margin: 2% 0 3% 0;
    text-align: center;
}

.captions__copy {
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 800;
    line-height: 2.4rem;
    width: 100%;
    margin: 2% 0 2% 0;
    text-align: start;
    transition: .4s ease-in-out;
}

.guide__buttonContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 4%;
}

#button_ringback {
    height: 100%;
    width: 50%;
    border: 2px solid #FFFFFF;
    background-color: #EEAECA;
    color: #FFFFFF;
    transition: .3s ease-in-out;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 800;
    font-size: 5rem;
    padding: 4% 0 4% 0;
}

#button_ringback:hover {
    background-color: #1d6187;
}

#button_ringnext {
    height: 100%;
    width: 50%;
    opacity: 70%;
    border: 2px solid #FFFFFF;
    background-color: #EEAECA;
    color: #FFFFFF;
    transition: .3s ease-in-out;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 800;
    font-size: 5rem;
    padding: 4% 0 4% 0;
}

#button_ringnext:hover {
    opacity: 100%;
    background-color: #008800;
}

.Ring__return {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20% 0 20% 0;
}

.return__link {
    border: 2px solid #FFFFFF;
    border-radius: 25px;
    background-color: #1d6187;
    height: 100%;
    width: 60%;
    text-align: center;
    text-decoration: none;
    color: #FFFFFF;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 800;
    font-size: 4rem;
    padding: 7% 0 7% 0;
    transition: .4s ease-in-out
}

.return__link:hover {
    background-color: #EEAECA;
}

#ring_bullshit {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    
}

.guide__c4sale {
    height: 100%;
    width: 100%;
    margin: 2% 0 0 0;
}

@media only screen and (max-width: 1025px) {
    .guide__viewContainer {
        padding: 25% 25% 25% 25%;
    }
    .guide__captions {
        width: 96%;
    }
    .captions__title {
        line-height: 4.4rem;
    }
    .captions__copy {
        width: 96%;
    }
    .return__link {
        width: 80%;
    }
}

@media only screen and (max-width: 800px) {
    .return__link {
        width: 96%;
        font-size: 3.6rem;
    }
}

@media only screen and (max-width: 600px) {
    .guide__viewContainer {
        padding: 35% 35% 35% 35%;
    }
    .captions__copy {
        font-weight: 400;
        text-align: center;
        margin-bottom: 3%;
    }
    .guide__captions {
        margin-bottom: 12%;
    }
    .return__link {
        width: 96%;
        padding: 12% 0 12% 0;
        font-size: 2.4rem;
    }
}