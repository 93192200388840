.TestimonialsPreview {
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #FFFFFF;
    
    /* background-image: url("../../assets/textures/texture3_black&purp.svg"); */
    background-position: center;
    background-size: cover;
    background-color: #FFFFFF;
    overflow: hidden;
}

.TestimonialsPreview__headerContainer {
    height: 100%;
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 2% 0 1% 0;
    padding: 2.5% 5% 2% 5%;
    background-color: #0B72B6;
}

#TestCont_header {
    color: #1D6187;
    font-size: 7rem;
    line-height: 8rem;
    font-family: 'Courier New', Courier, monospace;
    letter-spacing: 2px;
    font-weight: 1000;
    width: auto;
    height: 100%;
    padding: 0 0 0 0;
    color: #FFFFFF;
}

#TestCont_header > span {
    font-style: oblique;
    text-decoration: underline;
}



.TestimonialsPreview__copyContainer {
    /* border: 2px solid green; */
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin: 1% 0 1% 0;
}

.copyContainer__quoteContainer {
    /* border: 1px solid purple; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 1.5% 0 1.5% 0;
    padding: 2% 0 2% 1.5%;
    background-color: #0B72B6;
    transition: .3s ease-in-out;
    /* background: url("../../assets/textures/mix_texture_blue&pink.svg"); */
    /* background-size: cover; */
}

/* .TestimonialsPreview__copyContainer > :nth-child(1) {
    background-position: center;
}

.TestimonialsPreview__copyContainer > :nth-child(2) {
    background-position: bottom;
}

.TestimonialsPreview__copyContainer > :nth-child(3) {
    background-position-y: 20%;
    
} */


.quoteContainer__quote {
    /* background-color: #1D6187; */
    font-style: italic;
    font-size: 3.5rem;
    line-height: 4.5rem;
    font-weight: 1000;
    letter-spacing: 1px;
    padding: 1.25% 7% 1.25% 2%;
    transition: .2s ease-in-out;
}

.quoteContainer__atr {
    font-weight: 1000;
    font-size: 2.5rem;
    font-style: oblique;
    font-family: 'Courier New', Courier, monospace;
    letter-spacing: 2px;
    padding: 3% 2% 1% 2%;
    transition: .2s ease-in-out;
    width: 96%;
    text-align: end;
    text-decoration: underline;
    color: #FFFFFF;
    font-weight: 600;

}

/* ? enhancement code for the links to jump out to other sources (styling support) */

.TestimonialsPreview__link {
    /* border: 1px solid red; */
    text-decoration: none;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 80%;
    margin: 2% 3% 3% 3%;
    width: 90%;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0B72B6;
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    font-weight: 600;
    transition: .5s ease-in-out;
    border-radius: 25px;
}

.TestimonialsPreview__link > :nth-child(1) {
    border-radius: 10px;
    transition: .2s ease-in-out;
    width: 100%;
    text-align: center;
}

.TestimonialsPreview__link:hover {
    background-color: #EEAECA;
}

.TestimonialsPreview__link:hover > :nth-child(1) {
    color: #FFFFFF;
    width: 90%;
    padding-left: 0;
}




/* .link__hidden {
    display: block;
    width: 30%;
    height: 30%;
    padding: 1% 1% 1% 1%;
    margin-left: 3%;
    background-color: #FFFFFF;
    border-radius: 35px;
    color: #1D6187;
    font-weight: 1000;
    letter-spacing: 1px;
    text-align: center;
    transition: .7s ease-in-out;
} */

/* .TestimonialsPreview__link:hover > div > :nth-child(1) {
    color: #EEAECA;
} */



@media only screen and (max-width: 1500px) {
    .copyContainer__quoteContainer {
        padding-top: 3%;
        padding-bottom: 3%;
    }
    #TestCont_header {
        font-size: 5.75rem;
    }
}

@media only screen and (max-width: 1350px) {
    #TestCont_header {
        font-size: 5rem;
    }
    .quoteContainer__quote {
        letter-spacing: 1.25px;
    }
}

@media only screen and (max-width: 1024px) {
    .quoteContainer__quote {
        font-weight: 600;
        font-size: 3.5rem;
        line-height: 4rem;
    }
    #TestCont_header {
        font-size: 4rem;
    }
}

@media only screen and (max-width: 800px) {
    .quoteContainer__quote {
        font-weight: 400;
        font-size: 2.75rem;
        line-height: 3.5rem;
    }
    .TestimonialsPreview__link {
        width: 92%;
    }
    #testimonials_link {
        font-size: 2.5rem;
    }
    .TestimonialsPreview__link:hover > div {
        width: 80%;
    }

}

@media only screen and (max-width: 600px) {
    .quoteContainer__quote {
        font-size: 2rem;
        line-height: 3.25rem;
        font-weight: 400;
        letter-spacing: 1px;
        padding: 5% 3% 5% 3%;
    }
    .quoteContainer__atr {
        font-size: 1.75rem;
        letter-spacing: 1px;
    }
    #TestCont_header {
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .TestimonialsPreview__link {
        width: 90%;
    }
    .TestimonialsPreview__link > div > :nth-child(1) {
        width: 100%;
    }
    .TestimonialsPreview__link:hover > div > :nth-child(1) {
        font-size: 2rem;
    }
}