.TopicHome {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(350deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
}

.TopicHome__loadingcontainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to{
        transform: rotate(359deg);
    }
    }

.three-quarter-spinner {
    width: 250px;
    height: 250px;
    border: 3px solid transparent;
    box-shadow: 4px 2px 15px 7px #ffff78;
    border-radius: 50%;
    animation: spin .5s linear 0s infinite;
    margin: 7% 0 7% 0;
}

.menucontainer__introContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    color: #ffffff;
}

.introContainer__title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 6rem;
    font-weight: 800;
    padding: 0 0 0 3%;
    margin: 2% 0 2% 0;
}

.introContainer__caption  {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    height: 100%;
    width: 80%;
    padding: 0 0 0 3%;
    line-height: 3.4rem;
}

.introContainer__email {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    height: 100%;
    width: 80%;
    padding: 0 0 0 3%;
    margin: 2% 0 2% 0;
}

#TH_emailink {
    color: #ffffff;
    transition: .2s ease-in-out;
}

#TH_emailink:hover {
    color: #EEAECA;
}

.TopicHome__menucontainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.menucontainer__topic {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3.4% 3% 4% 3%;
    text-decoration: none;
    transition: .4s ease-in-out;
    border-bottom: .5px solid #ffffff;
}

#topichome_URC000 {
    border-top: .5px solid #FFFFFF;
}

.topic__arrow {
    color: #ffffff;
    font-size: 5rem;
}

.menucontainer__topic:hover {
    background-color: #ffff78;
}

.menucontainer__topic:hover > p {
    color: #000000;
    text-decoration: none;
}

.topic__frame {
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.frame__closebutton {
    height: 10%;
    width: 100%;
    border: 1px solid #FFFFFF;
    background-color: #1D6187;
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    transition: .3s ease-in-out;
}

.frame__closebutton:hover {
    background-color: #EEAECA;
    color: #FFFFFF;
    border: 2px solid #FFFFFF;
}

@media only screen and (max-width: 1600px) {

}


@media only screen and (max-width: 1240px) {
    .introContainer__title {
        font-size: 3rem;
    }
}


@media only screen and (max-width: 800px) {
    .introContainer__title {
        font-size: 2.4rem;
        padding: 3% 0 3% 3%;
        margin: 3% 0 2% 0;
    }

    .introContainer__caption {
        font-size: 1.4rem;
        line-height: 2.4rem;
        width: 95%;
    }

    .introContainer__email {
        font-size: 1.4rem;
        margin-top: 7%;
        margin-bottom: 7%;
    }
    .KHContainer__listItem {
        padding: 15.4% 5% 15% 3%;
    }

    /* !prevents titles from disappearing at media breakpoint from other comp stylings */
    .KHContainer__listItem > p {
        display: block;
        font-weight: 400;
        font-size: 1.6rem;
    }
    /* ! */

    .listitem__arrow {
        font-size: 3rem;
    }

    #KHlist_title_1 {
        width: 60%;
        line-height: 2.8rem;
    }
    #KHlist_title_5 {
        width: 60%;
        line-height: 2.8rem;
    }
}


@media only screen and (max-width: 700px) {

}
