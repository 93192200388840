.Error {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.Error__copy {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #FFFFFF;
    font-weight: 800;
    text-align: center;
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#userTip {
    border: 1px dashed #F20000;
    background-color: #FFFFFF;
    padding: 7% 5% 7% 5%;
    color: #F20000;
    font-weight: 500;
    width: 90%;
}

.Error__copy > :nth-child(2) {
    padding-top: 2%;
}

.Error__copy > :nth-child(3) {
    padding-top: 1%;
    padding-bottom: .5%;
}

.Error__homeBtnContainer {
    height: 40%;
    width: 100%;
    padding: 0 0 2% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#btn__processingError {
    height: 100%;
    width: 30%;
    background-color: #ffff78;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 400;
    font-size: 2rem;
    border-radius: 12px;
    border: 2px solid #FFFFFF;
    transition: .25s ease-in-out;
    margin-top: 3%;
}

#btn__processingError:hover {
    background-color: #EEAECA;
    font-weight: 800;
    color: #FFFFFF;
}



/* ? formatting for animation from here, down. */

.errmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 10;
    stroke-miterlimit: 30;
    stroke: #F20000;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.errmark {
    width: 100px;
    height: 100px;
    margin-top: 5%;
    border-radius: 100%;
    stroke-width: 5;
    stroke: #fff;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #F20000;
    animation: fill_e .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.errmark__x {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards

}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none
    }

    50% {
        transform: scale3d(1.1, 1.1, 1)
    }
}

@keyframes fill_e {
    100% {
        box-shadow: inset 0px 0px 0px 50px #F20000
    }
}

/* * MOBILE RESPONSIVE IMPLEMENTATION * */
@media only screen and (max-width: 1500px) {

}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1000px) {
    .errmark {
        margin-top: 7%;
    }
    .copy__subtitle {
        font-size: 1.5rem;
    }

}

@media only screen and (max-width: 800px) {
    #title_error {
        font-size: 3.75rem;
    }

    #btn__processingError {
        width: 70%;
        height: 100%;
        padding: 3% 0 3% 0;
        margin-top: 10%;
    }
}

@media only screen and (max-width: 600px) {

}