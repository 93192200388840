.Positions {
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image: url("../../assets/textures/texture3_black&purp.svg");
}

.Positions__job {
    /* border: 2px solid green; */
    height: 100%;
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #FFFFFF;
    margin: 2% 0 2% 0;
}

.job__positiontitleContainer {
    /* border: 2px solid slateblue; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-left: 5%;
    margin-top: 1%;
}

.positiontitleContainer__title {
    font-family: 'Courier New', Courier, monospace;
    font-size: 5rem;
    font-weight: 1000;
    color: #0B72B6;
    letter-spacing: 1px;
    padding: 1.25% 0 .75% 0;

}

.positiontitleContainer__subtitle {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    margin-left: 3%;
    width: 40%;
    line-height: 2.5rem;
    font-weight: 400;
}

.job__jobinfoContainer {
    /* border: 2px solid orangered; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.jobinfoContainer__title {
    font-family:'Courier New', Courier, monospace;
    font-size: 2.5rem;
    font-weight: 600;
    padding: 1% 0 0 3%;
}

.jobinfoContainer__bullet {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    padding: .5% 0 1% 5%;
    line-height: 1.75rem;
    width: 45%;

}

.bullet {
    padding-right: .5%;
}

.job__requirementsContainer {
    /* border: 2px solid greenyellow; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.requirementsContainer__title {
    font-family: 'Courier New', Courier, monospace;
    font-size: 2.5rem;
    font-weight: 600;
    padding: 1% 0 .5% 3%;
}

.requirementsContainer__bullet {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    padding: .5% 0 1% 5%;
    line-height: 1.75rem;
    width: 45%;

}

.job__compensationContainer {
    /* border: 2px solid greenyellow; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.compensationContainer__title {
    font-family: 'Courier New', Courier, monospace;
    font-size: 2.5rem;
    font-weight: 600;
    padding: 1% 0 .5% 3%;
}

.compensationContainer__detailContainer {
    height: 100%;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detailContainer__subtitle {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 600;
    padding: 1% 0 20% 4%;
}

strong {
    text-decoration: none;
    color: #0B72B6;
}

.detailContainer__benefitsContainer {
    /* border: 2px solid red; */
    height: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 40%;
    margin: 0 0 2% 2%;
}

.benefitsContainer__title {
    font-family: 'Courier New', Courier, monospace;
    font-size: 2.5rem;
    font-weight: 600;
    padding: 3% 0 2% 5%;
}

.benefitsContainer__rule {
    width: 100%;
    border: 1px solid #3C3C60;
    opacity: 40%;
}


.benefitsBox__bullet {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.75rem;
    padding: .25% 0 .5% 5%;
    line-height: 2.75rem;   
}

.job__link {
    border: 2px solid #3C3C60;
    border-radius: 25px;
    background-color: #3C3C60;
    text-decoration: none;
    height: 100%;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2%;
    transition: .5s ease-in-out;
}

.job__link:hover {
    background-color: #008000;
    border: 2px solid #FFFFFF;
}


.link__button {
    height: 100%;
    width: 100%;
}

#button_Positions {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1024px) {
    .jobinfoContainer__bullet {
        width: 70%;
    }
    .compensationContainer__title {
        padding-top: 3%;
        padding-bottom: 2%;
    }
    .compensationContainer__detailContainer {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
    }
    .detailContainer__subtitle {
        padding-bottom: 5%;
    }
    .detailContainer__benefitsContainer {
        padding-left: 4%;
    }
    #apply_lable {
        font-size: 2rem;
        padding-right: 7%;
    }
}




@media only screen and (max-width: 600px) {
    .positiontitleContainer__title {
        font-size: 3rem;
        letter-spacing: 0px;
    }
    .positiontitleContainer__subtitle {
        width: 92%;
        margin: 2% 0 3% 0;
        padding: 0 3% 0 5%;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 600;
        font-style: oblique;
        opacity: 50%;
    }
    .jobinfoContainer__bullet {
        width: 90%;
        padding: 0 3% 0 9%;
        margin: 2% 0 4% 0;
    }
    .requirementsContainer__bullet {
        width: 90%;
        padding: 0 3% 0 9%;
        margin: 2% 0 3% 0;
    }
    .compensationContainer__detailContainer {
        flex-direction: column;
    }
    .compensationContainer__title {
        margin: 5% 0 0 0;
    }
    .detailContainer__subtitle {
        padding: 0 0 0 0;
        margin: 7% 0 7% 9%;
    }
    .detailContainer__benefitsContainer {
        width: 90%;
    }
    .benefitsContainer__title {
        font-size: 2.5rem;
    }
    .benefitsBox__bullet {
        font-size: 1.75rem;
    }
    .job__link {
        width: 90%;
        margin: 0 0 5% 0;
    }
    #button_Positions {
        width: 90%;
    }
}