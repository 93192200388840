.Help {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Help__title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 6rem;
    font-weight: 800;
    color: #FFFFFF;
    margin: 2% 0 2% 0;
    height: 100%;
    width: 100%;
    text-align: center;
}

.Help__copy {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    font-weight: 400;
    color: #FFFFFF;
    margin: 2% 0 2% 0;
    height: 100%;
    width: 100%;
    text-align: center;
    line-height: 3.6rem;
}

.Help__container {
    height: 100%;
    width: 30%;
    padding: 2% 0 2% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.phone__number {
    height: 100%;
    width: 80%;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    margin: 2% 0 2% 0;
    display: flex;
    align-items: center;
    justify-content: center;

}

.phone__number > a {
    color: #FFFFFF;
    font-size: 1.8rem;
    padding-left: 3%;
}

.Help__email {
    height: 100%;
    width: 80%;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    margin: 3% 0 2% 0;
    display: flex;
    align-items: center;
    justify-content: center;

}

.Help__email > a {
    color: #FFFFFF;
    font-size: 1.8rem;
    padding-left: 3%;
}




.phone__icon {
    padding: 1% 2% 1% 2%;
    display:inline-block;
    height: 50px;
    width: 50px;
    margin: 0 1% 0 0;
    background: url("../../assets/icons/phone.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.email__icon {
    padding: 3% 2% 2% 2%;
    display:inline-block;
    height: 50px;
    width: 50px;
    margin: 0 1% 0 0;
    background: url("../../assets/icons/mail.svg");
    background-repeat: no-repeat;
    background-position: center;
}

/* * MOBILE RESPONSIVE IMPLEMENTATION * */
@media only screen and (max-width: 1500px) {

}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1000px) {
    .Help__title {
        font-size: 3rem;
    }

    .Help__copy {
        font-size: 2rem;
        line-height: 2.6rem;
    }
    .Help__container {
        width: 100%;
        padding-top: 10%;
        padding-bottom: 10%;
    }


}

@media only screen and (max-width: 800px) {

}

@media only screen and (max-width: 600px) {

}