.WifiIntro {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(350deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
}

.WifiIntro__copyContainer {
    height: 100%;
    width: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 7% 0 9% 0;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#title_wifiintro {
    font-weight: 900;
    width: 100%;
}

#subtitle_wifiintro {
    width: 100%;
    padding-bottom: 2%;
}

#copy_wifiintro {
    width: 100%;
}

#quote_wifiintro {
    font-size: 2.5rem;
    text-shadow: 1px 1px 5px #FFFFFF;
    text-decoration: underline;
    transition: .4s ease-in-out;
}

#quote_wifiintro:hover {
    padding: 1% 1% 1.2% 1%;
    background-color: #FFFF87;
    color: #000000;
    text-shadow: 1px 1px 5px #000000;
    border-radius: 15px;

}

.WifiIntro__controlContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-top: 10px ridge #ffffff;
}

#control_option1 {
    border-right: 1.5px solid #ffffff;
}

#control_option2 {
    border-left: 1.5px solid #ffffff;
}

.controlContainer__option {
    height: 100%;
    width: 100%;
    transition: .4s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    text-decoration: none;
    color: #FFFFFF;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 1% 0 5% 0;
    opacity: 90%;
}

.controlContainer__option:hover {
    opacity: 100%;
    background-color: #EEAECA;
    color: #FFFFFF;
}


.option__imgContainer {
    height: 400px;
    width: 80%;
    transition: .4s ease-in-out;

}

#img_option1 {
    background-image: url("../../../assets/img/TOB/option1.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

#img_option2 {
    background-image: url("../../../assets/img/TOB/option2.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

#title_option1 {
    font-weight: 900;
    width: 100%;
}

#subtitle_option1 {
    width: 100%;
    padding-bottom: 2%;
    padding-top: 1%;
}

#copy_option1 {
    width: 100%;
}

#quote_option1 {
    font-size: 2.5rem;
    text-shadow: 1px 1px 5px #FFFFFF;
}

#title_option2 {
    font-weight: 900;
    width: 100%;
}

#subtitle_option2 {
    width: 100%;
    padding-bottom: 2%;
    padding-top: 1%;
}

#copy_option2 {
    width: 100%;
}

#quote_option2 {
    font-size: 2.5rem;
    text-shadow: 1px 1px 5px #FFFFFF;
}


.WifiIntro__back {
    height: 100%;
    width: 100%;
    transition: .4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 4rem;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 800;
    text-decoration: none;
    color: #ffffff;
    padding: 5% 0 5% 0;
    border-top: 2px dashed #FFFFFF;
    opacity: 40%;
}

.WifiIntro__back:hover {
    opacity: 100%;
    background-color: #FFFFFF;
    color: #1d6187;
}

#mview_wifiintro {
    display: none;
}

@media only screen and (max-width: 1025px) {
    #subtitle_wifiintro {
        font-size: 2.2rem;
        width: 70%;
        margin-top: 3%;
    }

    .copyContainer__copy {
        font-size: 1.6rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    #copy_wifiintro {
        width: 90%;
    }
    
    #quote_wifiintro {
        display: none;
    }

    .WifiIntro__controlContainer {
        flex-direction: column;
    }

    #control_option1 {
        border-bottom: 2px dashed #FFFFFF;
    }

    .option__copyContainer {
        margin-bottom: 3%;
    }

    #mview_wifiintro {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 65%;
        border: 2px solid red;
        margin: 5% 0 5% 0;
        padding: 2% 0 2% 0;
        font-size: 2rem;
        line-height: 2.6rem;
        background-color: #FFFFFF;
        border: 5px solid #B5333A;
        border-radius: 15px;
        color: #B5333A;
    }
}

@media only screen and (max-width: 800px) {
    #mview_wifiintro {
        width: 90%;
    }
}

@media only screen and (max-width: 600px) {
    #mview_wifiintro {
        font-size: 1.6rem;
    }
    #subtitle_wifiintro {
        width: 90%;
        margin-bottom: 5%;
    }
}