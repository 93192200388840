.Wifioption1 {
    height: 100%;
    width: 100%;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: -webkit-linear-gradient(360deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    background: linear-gradient(350deg, rgba(255,255,255,0) 0%, rgba(29,97,135,1) 89%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1d6187",GradientType=1); 
    background-color: #EEAECA;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.Wifioption1__guide {
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5% 0 0 0;
}

.guide__viewContainer {
    height: 100%;
    width: 30%;
    padding: 15% 0 15% 0;
    transition: .6s ease-in-out;
}

#viewer_op1_0 {
    background-image: url("../../../assets/img/TOB/option1.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

#viewer_op1_1 {
    background-image: url("../../../assets/img/TOB/option1_step1.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

#viewer_op1_2 {
    background-image: url("../../../assets/img/TOB/option1_step2.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

#viewer_op1_3 {
    background-image: url("../../../assets/img/TOB/option1_step3.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

#viewer_op1_4 {
    background-image: url("../../../assets/img/TOB/option1_all.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.guide__captions {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.captions__title {
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 4rem;
    font-weight: 800;
    line-height: 2.4rem;
    width: 100%;
    margin: 2% 0 3% 0;
    text-align: center;
}

.captions__copy {
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 800;
    line-height: 2.4rem;
    width: 100%;
    margin: 2% 0 2% 0;
    text-align: start;
    transition: .4s ease-in-out;
}

.guide__buttonContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 4%;
}

#button_wifiback {
    height: 100%;
    width: 50%;
    border: 2px solid #FFFFFF;
    background-color: #EEAECA;
    color: #FFFFFF;
    transition: .3s ease-in-out;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 800;
    font-size: 5rem;
    padding: 4% 0 4% 0;
}

#button_wifiback:hover {
    background-color: #1d6187;
}

#button_wifinext {
    height: 100%;
    width: 50%;
    opacity: 70%;
    border: 2px solid #FFFFFF;
    background-color: #EEAECA;
    color: #FFFFFF;
    transition: .3s ease-in-out;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 800;
    font-size: 5rem;
    padding: 4% 0 4% 0;
}

#button_wifinext:hover {
    opacity: 100%;
    background-color: #008800;
}

.Wifioption1__return {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20% 0 20% 0;
}

.return__link {
    border: 2px solid #FFFFFF;
    border-radius: 25px;
    background-color: #1d6187;
    height: 100%;
    width: 60%;
    text-align: center;
    text-decoration: none;
    color: #FFFFFF;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 800;
    font-size: 4rem;
    padding: 7% 0 7% 0;
    transition: .4s ease-in-out
}

.return__link:hover {
    background-color: #EEAECA;
}