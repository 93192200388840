.GalleryBanner {
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 5px 5px 10px #000000;
}

.GalleryBanner__titleContainer {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 35%;
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 5% 0% 5% 0%;    
}

.titleContainer__title {
    color: #0B72B6;
    font-size: 4.2rem;
    font-weight: 1000;
    letter-spacing: 3px;
    width: 80%;
}

.titleContainer__caption {
    width: 80%;
    height: 100%;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    padding-left: 1%;
    margin-top: 2%;
    letter-spacing: .5px;
}

.GalleryBanner__copyContainer {
    /* border: 2px solid red; */
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.GalleryBanner__copyContainer > h3 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 4rem;
    font-weight: 1000;
    letter-spacing: 2px;
    margin-bottom: 1.5%;
}

.GalleryBanner__copyContainer > p {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.75rem;
    line-height: 2rem;
    width: 80%;
    font-weight: 400;
    padding-left: 1%;
    padding-bottom: 1.25%;
}

.bullet {
    padding-right: 1%;
}

@media only screen and (max-width: 1024px) {
    .GalleryBanner__titleContainer {
        padding: 8% 0% 8% 0%;    
    }

}

@media only screen and (max-width: 800px) {
    .GalleryBanner {
        flex-direction: column;
        margin-bottom: 0;
    }
    .GalleryBanner__titleContainer {
        width: 100%;
    }
    .GalleryBanner__copyContainer {
        width: 100%;
        padding: 5% 0% 5% 11%;
        box-shadow: inset 0 0 5px 1px #3C3C60;
    }
}



@media only screen and (max-width: 600px) {
    .GalleryBanner__copyContainer {
        width: 100%;
        padding: 5% 5% 5% 5%;
        box-shadow: inset 0 0 5px 1px #3C3C60;
    }
    .GalleryBanner__copyContainer > p {
        padding-left: 5%;
        width: 96%;
        padding-bottom: 5%;
    }
    .GalleryBanner__copyContainer > :first-child {
        margin-top: 2%;
    }
    .GalleryBanner__copyContainer > :nth-child(2) {
        margin-top: 3%;
    }
    
}