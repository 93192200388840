.CoServices {
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* background-image: url("../../assets/textures/llline_3.svg"); */
    background-color: #000000;
    background-position: top;    
    background-size: cover;
    padding-bottom: 3%;
}

.CoServices__headerContainer {
    /* border: 2px solid green; */
    height: 100%;
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
}

.headerContainer__header {
    font-family:'Courier New', Courier, monospace;
    font-weight: 800;
    letter-spacing: 3px;
    padding-left: 25%;
    padding-top: 1.5%;
    padding-bottom: 3%;
    color: #FFFFFF;
    margin: 3% 0 3% 0;
}

#HP_serviceheader {
    font-size: 10rem;
    font-weight: 1000;
    padding: 1.5% 3.5% 1.5% 2.5%;
    background: #FFFF78;
    background-size: contain;
    color: #000000;
    -webkit-text-stroke: 2.5px #ffff78;
}

.headerContainer__subheader {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2rem;
    letter-spacing: 1.5px;
    font-weight: 600;
    margin: 8% 10% 2% 2%;;
    line-height: 2.5rem;
    color: #000000;
    height: 100%;
}

#HP_servicesubheader {
    padding: 1.5% 3.5% 1.5% 3.5%;
    background-color: #FFFF78;
    background-size: cover;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    width: 100%;
    font-size: 4rem;
    line-height: 5.5rem;
    color: #000000
}

.CoServices__buttonContainer {
    /* border: 2px solid purple; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.buttonContainer__link {
    /* border: 1px solid red; */
    text-decoration: none;
    transition: .4s ease-in-out;
    height: 100%;
    width: 30%;
}

.link__linkBox {
    border: 4px solid #ffff78;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 35px;
    transition: .2s ease-in-out;
    
}

.linkBox__label {
    color: #ffff78;
    font-family: 'Courier New', Courier, monospace;
    font-size: 4.75rem;
    font-weight: 1000;
    letter-spacing: 3px;
    transition: .2s ease-in-out;
    margin: 7.5% 5% 7.5% 3%;
    padding: 3% 10% 3% 10%;
    width: 80%;
    height: 100%;
    text-align: center;
}

/* ! removing per styling critique + fix Jul 2024 */
/* #residential {
    background-image: url("../../assets/HP_gallery/HPGal_10.jpg");
    background-position: 0 25%;
    background-size: cover;
}


#commercial {
    background-image: url("../../assets/HP_gallery/HPGal_4.jpg");
    background-position: 0 70%;
    background-size: cover;
}

#brands {
    background-image: url("../../assets/img/BRANDS.png");
    background-position: center;
    background-size: cover;
    background-position: top;
    /* little hotfix to center the brands word in the box while the rest are not quite center */
    /* padding-left: 2%; */
    /* end 
}
 */

/* Controls the hovering changes of images, not really in heirarchial order since it is somewhat of a "service" bleh. */
.link__linkBox:hover {
    border: 4px solid #0B72B6;    
}

.link__linkBox:hover p {
    color: #0B72B6;
}

.link__linkBox:focus {
    border: 4px solid #0B72B6;
    
}           

.link__linkBox:focus p {
    color: #0B72B6;
}

@media only screen and (max-width: 1600px) {
    .linkBox__label {
        padding: 3% 5% 2% 5%
    }
}

@media only screen and (max-width: 1500px) {
    .CoServices__headerContainer {
        flex-direction: column;
        margin-top: 9%;
    }
    #HP_serviceheader {
        margin: 0 0 0 0;
        font-size: 7rem;
        margin-top: 4%;  
    }
    .CoServices__buttonContainer {
        flex-direction: column;
    }
    .buttonContainer__link {
        width: 90%;
    }
    .linkBox__label {
        font-size: 5.5rem;
        letter-spacing: 1.5px;
        padding: 0 0 0 0;
        margin: 7% 0 7% 0;
    }
    #HP_servicesubheader {
        margin: 2% 0 5% 0;
        font-size: 3.5rem;
        line-height: 5rem;
    }
    #commercial {
        background: none;
        background-color: transparent;
    }
    #residential {
        background: none;
        background-color: transparent;
    }
    #brands {
        background: none;
        background-color: transparent;
    }
    .link__linkBox {
        border: 7px solid #FFFF78;
        margin-top: 4%;
        margin-bottom: 4%;
    }
}



@media only screen and (max-width: 600px) {
    .CoServices__headerContainer {
        flex-direction: column;
        margin-top: 9%;
    }
    #HP_serviceheader {
        margin: 0 0 0 0;
        font-size: 5.5rem;
        margin-top: 4%;  
        margin-bottom: 5%;
    }
    .linkBox__label {
        font-size: 3rem;
        padding: 0 0 0 0;
        margin: 7% 0 7% 0;
    }
    #HP_servicesubheader {
        margin: 4% 0 5% 0;
        font-size: 2.5rem;
        line-height: 3.5rem;
    }
    #commercial {
        background: none;
        background-color: transparent;
    }
    #residential {
        background: none;
        background-color: transparent;
    }
    #brands {
        background: none;
        background-color: transparent;
    }
    .link__linkBox {
        border: 5px solid #FFFF78;
        margin-top: 4%;
        margin-bottom: 4%;
    }
}
