.Contact {
    background-color: #000000;
}

.Footer__companyContainer {
    /* border: 2px solid green; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* flex-direction: column; */
}

