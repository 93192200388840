.TOBBanner {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: space-evenly;
    justify-content: center;
}

.TOBBanner__container {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: .5px 3px 15px 0px #FFFFFF;
}

#container_ACTOB {
    background-image: url("../../assets/img/htzl__theaterroom.jpg");
    background-size: cover;
    background-position: center;
    border-left: 3px solid #0B72B6;
    border-top: 15px ridge #0B72B6;
}

#container_TOB {
    background-image: url("../../assets/img/TOB/TOBhero.png");
    background-size: cover;
    background-position: center;
    border-right: 3px solid #46992A;
    border-top: 15px ridge #46992A;
}

.container__logo {
    height: 300px;
    width: 70%;
    margin: 3% 7% 3% 7%;
}

#logo_ACTOB {
    background-image: url("../../assets/icons/audio-contractors-new-logo-with-llc-no-tag-line.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

#logo_TOB {
    background-image: url("../../assets/icons/TOBLOGO.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

