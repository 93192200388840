.Footer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    flex-wrap: wrap;
    background: rgb(238,248,248);
    background: -moz-linear-gradient(180deg, rgba(238,248,248,0.32394964821866246) 0%, rgba(29,97,135,0.8029412448573179) 64%);
    background: -webkit-linear-gradient(180deg, rgba(238,248,248,0.32394964821866246) 0%, rgba(29,97,135,0.8029412448573179) 64%);
    background: linear-gradient(180deg, rgba(238,248,248,0.32394964821866246) 0%, rgba(29,97,135,0.8029412448573179) 64%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eef8f8",endColorstr="#1d6187",GradientType=1);  
    background-color: #1E132A;
    color: #FFFFFF;
}


.companyContainer__addressContainer {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    font-size: 2rem;
    line-height: 3rem;
    padding: 1% 0 4% 0;
    margin: 7% 0 3% 0;
    width: 30%;
    height: 100%;
}

#streetAddress {
    width: 100%;
    text-align: center;
}

#stateAddress {
    width: 100%;
    text-align: center;
}


.companyContainer__phone {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.75rem;
    line-height: 2.25rem;
}

.companyContainer__affiliateIcon {
    /* border: 2px solid red; */
    background-position: left;
    
}

#madisonbuilders {
    /* border: 2px solid red; */
    background-image: url("../assets/icons/maba-wordmark.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 30%;
    height: 100%;
    padding: 7% 0 7% 0;
    margin: 0 0% 0% 1%;
}

.infoContainer__socialContainer {
    /* border: 2px solid yellow; */
    height: 100%;
    width: 45%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3% 0 3% 1%;
}

.socialContainer__social {
    /* border: 2px solid red; */
    height: 75px;
    width: 75px;
    margin: 7% 0 17% 0;
    transition: .3s ease-in-out;
    border-radius: 35px;
}


#facebook {
    background-image: url("../assets/icons/facebook_black.svg");
    background-position:center;
    background-size: cover;
}

#instagram {
    background-image: url("../assets/icons/linkedin.svg");
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    border: 2px solid #FFFFFF;
}

#BBB {
    background-image: url("../assets/icons/BBB_logo.svg");
    background-position:center;
    background-size: cover;
}


/* * Hover state for the social media icons: */
.socialContainer__social:hover {
    background: #EEAECA;
}

.Footer__infoContainer {
    /* border: 2px solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 1%;
}

.Footer__siteMap {
    /* border: 2px solid orangered ; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 3% 0 3% 0;
    padding: 2% 0 2% 2%;
    
}

.siteMap__container {
    height: 100%;
    width: 27.5%;
    /* border: 2px solid green; */
}

.siteMap__link {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    font-weight: 600;
    margin: 3% 0 3% 0%;
    /* border: .5px solid #1E132A; */
    /* padding: 2.75% 5% 2.75% 5%;
    background-color:  #FFFF78; */
    /* color: #1E132A; */
    color: #ffffff;
    transition: .3s ease-in-out;
    height: 100%;
    width: auto;
}

.siteMap__link > :first-child {
    color: #ffffff;
    text-decoration: none;
    transition: .3s ease-in-out;
}

.siteMap__sublink > :first-child {
    color: #ffffff;
    text-decoration: none;
    transition: .3s ease-in-out;
}

.email__address > a {
    color: #FFFFFF;
    text-decoration: none;
}

.phone__number > a {
    text-decoration: none;
    color: #FFFFFF;
}

.addressContainer__address > a {
    color: #FFFFFF;
    text-decoration: none;
}

.siteMap__sublink {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.75rem;
    font-weight: 400;
    margin: 3% 0 3% 3%;
    /* padding: 1.5% 3% 1% 3%;
    background-color: #FFFF78; */
    color: #1E132A;
    transition: .3s ease-in-out;
}

.siteMap__link:hover {
    text-decoration: underline;
    color: #EEAECA;
    
}

.siteMap__link:hover > a {
    color: #EEAECA;
}

.siteMap__sublink:hover {
    text-decoration: underline;
    /* background-color: #EEAECA; */
    color: #EEAECA;
}

.siteMap__sublink:hover > a {
    color: #EEAECA;
}

.Footer__contactContainer {
    /* border: 2px solid red; */
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 2%;
}

.contactContainer__inTouchCont {
    /* border: 1px solid yellow; */
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-around;
    padding: 2% 0 2% 2%;
}

.inTouch__title {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    color: #FFFFFF;
    font-weight: 600;
    margin-bottom: 2%;
}

.inTouch__leade {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.6rem;
    color: #FFFFFF;
    line-height: 120%;
}

.contactContainer__contactLinkContainer {
    /* border: 1px solid green; */
    height: 500%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}

.contactLinkContainer__contactLink_link {
    height: 100%;
    width: 27%;
    text-decoration: none;
    color: #FFFFFF;
    transition: .2s ease-in-out;
    border: 3px solid transparent;
    padding: 2% 0 2% 0;
}

.contactLinkContainer__contactLink_link:hover {
    background-color: #EEAECA;
    border: 3px solid #FFFFFF;
}

.contactLinkContainer__contactLink {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.contactLink__img {
    height: 30px;
    width: 30px;
    margin-bottom: 3%;
}

@media only screen and (max-width: 1800px) {
    .contactContainer__email {
        padding-left: 20%;
    }
}

@media only screen and (max-width: 1500px) {
    .Footer {
        flex-direction: column;
    }
    #madisonbuilders {
        width: 500px;
    }
    .Footer__companyContainer {
        width: 100%;
        justify-content: space-between;
        padding-bottom: 5%;
    }
    .infoContainer__socialContainer {
        flex-direction: row;
        justify-content: space-around;
        width: 60%;
    }
    .Footer__infoContainer {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
    }
    
    .Footer__siteMap {
        width: 100%;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 0 2% 0;
        padding: 0 1% 0 3%;
    }
    .siteMap__sublink {
        margin: 1% 0 1% 3%;
    }
}

@media only screen and (max-width: 1350px) {
    .siteMap__sublink {
        width: auto;
    }
    .siteMap__link {
        width: auto;
    }
    .Footer__contactContainer {
        flex-direction: column;
    }
}

@media only screen and (max-width: 1024px) {
    .Footer__siteMap {
        padding: 0 1% 0 10%;
    }
    .infoContainer__contactContainer {
        flex-direction: column;
    }
    .Footer__siteMap {
        margin: 5% 0 10% 0;
        padding: 0 1% 0 3%;
        flex-direction: column;
    }
    .Footer__infoContainer {
        margin: 5% 0 5% 0;
        
    }
    .Footer__companyContainer {
        flex-direction: column;
        align-items: center;
    }
    #madisonbuilders {
        height: 100px;
        width: 300px;
        padding-left: 0;
        margin-left: 0;
        margin-top: 5%;
        margin-right: 2%;
    }
    .infoContainer__socialContainer {
        width: 100%;
        margin: 8% 0 4% 0;
    }
    .siteMap__container {
        width: 100%;
    }
    #StMpCntn_2 {
        margin-bottom: 5%;
    }
    .contactContainer__inTouchCont {
        text-align: center;
        margin-top: 4%;
    }


}

@media only screen and (max-width: 600px) {
    .Footer__siteMap {
        padding-right: 7%;
    }
    .contactContainer__inTouchCont {
        margin-bottom: 5%;
    }
    .contactContainer__contactLinkContainer {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .contactLinkContainer__contactLink_link {
        width: 80%;
        margin-bottom: 2%;
    }
}

@media only screen and (max-width: 361px) {
    .Footer__siteMap {
        padding-right: 5%;
    }
}
